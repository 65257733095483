import { RemoteDataService } from "./RemoteDataService";
import { LocalDataService } from "./LocalDataService";

export const ServiceHandle = (handle = "Local") => {
  let service: any;

  switch (handle) {
    case "API":
      service = RemoteDataService;
      break;

    case "Local":
      service = LocalDataService;
      break;

    default:
      service = {};
      break;
  }
  return service;
};
