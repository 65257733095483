import API from "domain/utils/API";
import { MiniToolbarRepository } from "domain/repository/MiniToolbar/MiniToolbarRepository";
import { UserInfoEntity, MiniToolbarAllEntity, WorkspaceItemEntity, BusinessUnitEntity, } from "domain/entity/MiniToolbar";

export class MiniToolbarRepositoryImpl implements MiniToolbarRepository {
  userInfo: UserInfoEntity = {
    userName: "",
    userEmail: "",
  };

  WorkspaceItem: WorkspaceItemEntity[] = [];

  BusinessUnit: BusinessUnitEntity[] = [
    {
      dropdownLabel: "SAPT",
      tagLabel: "01",
      value: "SAPT",
      lozengesLabel: "Default",
      lozengesVariation: "Ports Horizon Blue",
      isMaster: true,
    },
    {
      dropdownLabel: "HIT",
      tagLabel: "O2",
      value: "HIT",
    },
    {
      dropdownLabel: "OICT",
      tagLabel: "O2",
      value: "OICT",
    },
  ];

  getMiniToolbarInfo(): MiniToolbarAllEntity {
    return {
      userInfo: this.userInfo,
      WorkspaceItem: this.WorkspaceItem,
      BusinessUnit: this.BusinessUnit,
    };
  }

  async getWorkspacePreferences(): Promise<WorkspaceItemEntity | any> {
    try {
      const { data } = await API.get("/workspacepreference");
      return data.map(({ workspaceName }: any) => ({
        dropdownLabel: workspaceName,
        value: workspaceName,
      }));
    } catch (e) {
      throw e;
    }
  }
}
