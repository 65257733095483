import { DialogUtils } from "helpers/DialogUtils";
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DialogModelLess, IconButton } from "veronica-ui-component/dist/component/core";

export interface ISingleModal {
  modalId: string;
  modalClass: string;
  visible: boolean;
  draggable: boolean;
  resizable: boolean | undefined;
  showMaximiseButton: boolean | undefined;
  showMinimiseButton?: boolean | undefined;
  showCloseButton?: boolean;
  showModalAgain?: boolean;
  width: string;
  maskClassName: string
  headerHtml: JSX.Element | null;
  contentHtml: JSX.Element;
  style: React.CSSProperties;
  maskStyle: React.CSSProperties;
  baseZIndex: number;
  isMinimiseOnDrag?: boolean;
  minWidth?: number;
  minHeight?: number;
  focusModalId?: string;
  onFullScreen?: (e: React.MouseEvent<HTMLAnchorElement>, isFullScreen: boolean) => void;
  onClose: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMinimise?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMinimiseCallbackOnly?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onModalShowAgain?: (status: boolean) => void;
}

export const SingleModal: React.FC<ISingleModal> = (props: ISingleModal) => {
  const { modalId, modalClass, visible, draggable, resizable, showMaximiseButton, showMinimiseButton = false, showCloseButton = true, showModalAgain = true, width, headerHtml, contentHtml, style, maskStyle, baseZIndex, isMinimiseOnDrag = false, minWidth = 400, minHeight = 260, focusModalId, onFullScreen, onClose, onMinimise, onMinimiseCallbackOnly } = props;
  const [isModalShowAgain, setIsModalShowAgain] = useState<boolean>(true);
  const [isMinimise, setIsMinimise] = useState<boolean>(true);
  const [dragStartTime, setDragStartTime] = useState<any>(0);
  const [workspaceState] = useWorkspaceTracked();

  useEffect(() => {
    if (showModalAgain) setIsModalShowAgain(true);
  }, [showModalAgain]);

  useEffect(() => {
    focusModalId && focusModalId === modalId && DialogUtils.makeFullScreen(focusModalId);
  }, [focusModalId, modalId]);

  useEffect(() => {
    if (visible) DialogUtils.set(modalId, true, style.left?.toString(), style.top?.toString(), style.left?.toString(), style.top?.toString(), Number(maskStyle.zIndex), Number(style.zIndex));
  }, [visible, modalId, style, maskStyle]);

  // const handleMinimum = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
  //   setIsModalShowAgain(p => {
  //     onModalShowAgain && onModalShowAgain(!p);
  //     return !p;
  //   })
  // }, [onModalShowAgain]);

  const handleFullScreen = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    setIsMinimise(!isMinimise);
    onFullScreen && onFullScreen(e, !isMinimise);
    isMinimise && DialogUtils.makeFullScreen(modalId);
    isMinimise && DialogUtils.set(modalId, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    DialogUtils.resizeDialog(modalId);
  }, [isMinimise, modalId, onFullScreen]);

  const handleMinimise = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onMinimiseCallbackOnly) {
      onMinimiseCallbackOnly(e);
    } else {
      onMinimise && onMinimise(e);
      setIsMinimise(!isMinimise);
      onFullScreen && onFullScreen(e, !isMinimise);
      isMinimise && DialogUtils.makeFullScreen(modalId);
      isMinimise && DialogUtils.set(modalId, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      DialogUtils.resizeDialog(modalId);
    }
  }, [isMinimise, modalId, onMinimise, onFullScreen, onMinimiseCallbackOnly]);

  const handleDragStart = useCallback((e: any) => {
    if (isMinimiseOnDrag) {
      const d = new Date();
      setDragStartTime(d.getTime());
    }
    DialogUtils.makeFullScreen(modalId);
  }, [modalId, isMinimiseOnDrag]);

  const handleDragEnd = useCallback((e: any) => {
    const d = new Date();
    if (isMinimiseOnDrag && !isMinimise && (d.getTime() - dragStartTime) > 200) {
      isMinimise && setIsMinimise(true);
      onFullScreen && onFullScreen(e, true);
      !isMinimise && DialogUtils.makeFullScreen(modalId, true);
    }
  }, [modalId, isMinimiseOnDrag, dragStartTime, isMinimise, onFullScreen]);

  const handleResize = useCallback((e: any) => {
    if (e.target && e.target.offsetParent) {
      DialogUtils.resizeDialog(modalId);
    }
  }, [modalId]);

  const handleClose = useCallback((e: any) => {
    onClose(e);
  }, [onClose]);

  const getHeader = useMemo(() => {
    if (!headerHtml) return <div id={`${modalId}_header`} className={`md-header`} />;

    return <div className={`md-header`}>
      {headerHtml}
      <div className={`md-header-action`} style={{ width: ((showMaximiseButton || showMinimiseButton) && showCloseButton) ? '106px' : '32px' }}>
        {/* {<IconButton fileName={'Icon-save'} tooltipDisable={false} toolTipText={'Minimise'} size="medium" onClick={handleMinimum} />} */}
        {showMaximiseButton && <IconButton fileName={!isMinimise ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={isMinimise ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />}
        {showMinimiseButton && <IconButton fileName={isMinimise ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={isMinimise ? 'Minimise' : 'Restore'} size="medium" onClick={handleMinimise} />}
        {showCloseButton && <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} />}
      </div>
    </div>;
  }, [headerHtml, modalId, showMaximiseButton, showMinimiseButton, showCloseButton, isMinimise, handleFullScreen, handleMinimise, handleClose]);

  return (
    // <Suspense fallback={<Loader Indicator="Stripe" size="Large" />}>
      <DialogModelLess
        id={modalId}
        key={modalId}
        className={`single-modal-dialog ${modalClass} ${isMinimise ? 'minimise' : 'enlarge'} ${(isMinimise && workspaceState?.navigationBarPinned) ? 'pined-enlarge' : ''}`}
        style={{ ...style, ...(minWidth ? { minWidth: `${minWidth}px` } : {}), ...(minHeight ? { minHeight: `${minHeight}px` } : {}) }}
        visible={visible}
        positions="center"
        header={getHeader}
        dialogContent={contentHtml}
        draggable={draggable}
        resizable={resizable}
        text=""
        width={width}
        maskClassName={`single-modal-mask ${!isModalShowAgain && 'p-dailog-minimise'} ${props.maskClassName} ${isMinimise ? '' : 'p-arpita-modal-fs'}`}
        maskStyle={maskStyle}
        baseZIndex={baseZIndex}
        maximizable={true}
        maximized={!isMinimise}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onResize={handleResize} />
      // </Suspense>
  );
}