import { UserActivityLoginTrackingEntity } from "domain/entity/UserTracking/UserActivityLoginTrackingEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { UserActivityLoginTrackingRepo } from "./UserActivityLoginTrackingRepo";

export const UserActivityLoginTrackingRepoImpl = (): UserActivityLoginTrackingRepo => {
    const url = '/v1/useractivitylogintracking';

    const createNewLoginTracking = async(newData: UserActivityLoginTrackingEntity) => {
        await axiosPostData(authAxiosInstance, url, newData);
    };

    return {
        createNewLoginTracking: createNewLoginTracking,
    };
};
