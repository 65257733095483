import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { useEffect } from "react";

export const SwitchBuIframe = () => {
    const { anaKeycloak } = useANAInfo();

    useEffect(() => {
        //ana using
        // const switchBu = (e: any) => {
        //     const data = e.data;
        //     if (data.switchingBu === true) {
        //         if (data.targetUrl) {
        //             window.location = data.targetUrl; // Target BU’s URL
        //             window.location.reload();
        //         } else {
        //             anaKeycloak?.login();
        //         }
        //     }
        // }
        const switchBu = (e: any) => {
            const data = e.detail;
            if (data?.switchingBu === true) {
                if (data?.targetUrl) {
                    window.location = data?.targetUrl; // Target BU’s URL
                } else {
                    anaKeycloak?.login();
                }
            }
        }
        // window.addEventListener('message', switchBu);
        document.addEventListener('message', switchBu);
        return () => {
            // window.removeEventListener('message', switchBu)
            document.removeEventListener('message', switchBu);
        }
    }, [anaKeycloak])
    return (
        <iframe id="switch-bu-iframe" name="switch-bu-iframe" src="" title='switch-bu-iframe'
            style={{
                position: 'absolute',
                backgroundColor: 'white',
                top: '0px',
                left: '0px',
                zIndex: '9999',
                width: '0px',
                height: '0px'
            }} />
    )
}