export const commonNotification = {
  workspaceExist: {
    msg: (label: any) => `"${label}" already exists. Please rename it.`,
    bg: "Alert Red",
    counter: 20,
  },
  workspaceSaved: {
    msg: (label: any) => `"${label}" saved successfully.`,
    bg: "Success Aqua Green",
    counter: 20,
  },
  workspaceDeleted: {
    msg: (label: any) => `"${label}" deleted successfully.`,
    bg: "Success Aqua Green",
    counter: 20,
  },
  mfeFailure: {
    msg: "This page is under construction. Please try again later.",
    bg: "Alert Red",
    counter: 20,
  },
};
