import { MenuRepoImpl } from "domain/repository/Menu/MenuRepoImpl";
import { UserRepoImpl } from "domain/repository/User/UserRepoImpl";
import { UserGroupRepoImpl } from "domain/repository/UserGroup/UserGroupRepoImpl";
import { useChangePasswoTracked } from "presentation/store/ChangePassword/ChangePasswordProvider";
import { useUserTracked } from "presentation/store/User/UserProvider";
import { UserVM } from "presentation/viewModel/User/UserVM";
import { useMemo } from "react";

export const useUserVM = () => {
    const [, setUserState] = useUserTracked();
    const [, setChangePasswordState] = useChangePasswoTracked();
    const userVM = useMemo(() =>
        UserVM({
            dispatch: [setUserState, setChangePasswordState],
            userGroupRepo: UserGroupRepoImpl(),
            menuRepo: MenuRepoImpl(),
            userRepo: UserRepoImpl(),
        }), [setUserState, setChangePasswordState])

    return userVM;
}