import { convertTemplateId } from "presentation/view/components/workspace/Modal/Modal";
import React from "react";
import { E_ArpitaOperation } from "./Events";

function handler() {
  const selector: string = `body.modal > .p-dialog-mask .charge-search-modal.single-modal-dialog`;
  const amSelector: string = `body.modal > .p-dialog-mask .movement-search-modal.single-modal-dialog`;
  const docSelector: string = `body.modal > .p-dialog-mask .document-search-modal.single-modal-dialog`;

  const mfeContainer = (entrypoint?: string, sectionId?: string) => {
    const id: string = ((entrypoint ? entrypoint : '') + (sectionId ? `_${sectionId}` : '')).replaceAll(' ', '_').replaceAll("'", '_').replaceAll("+", '_');
    return `a-${id.toString().toLowerCase()}-a`;
  }

  const addRemoveControlTowerClassOnBody = (className: string) => {
    let cls: string = document.body.getAttribute('class') || '';

    cls = cls.replaceAll('control-tower-cls', '');
    if (className.indexOf('BuildMap') >= 0 || document.getElementById('a-buildmap-a')) cls = `${cls ? cls + ' ' : ''}control-tower-cls`;

    document.body.setAttribute('class', cls);
  }

  const getMfeClassName = (entrypoint: string, sectionId?: string) => {
    return `head_element_${entrypoint}${sectionId && `_${convertTemplateId(sectionId)}`}`;
  }

  const createScript = (className: string, entrypoint: string, scriptPath: string, sectionId?: string, jsonData?: string) => {
    const script = document.createElement("script");
    script.className = className;
    script.crossOrigin = "";
    script.setAttribute("app-name", mfeContainer(entrypoint, sectionId));
    if (jsonData) script.setAttribute("arpita-template-data", jsonData);
    if (sectionId) script.setAttribute("arpita-template-id", sectionId);
    if (sectionId) script.setAttribute("sectionId", sectionId);
    if (sectionId && sectionId === E_ArpitaOperation.NEW) script.setAttribute("arpita-new-template", 'true');
    script.src = `${scriptPath}?params=10`;
    return script;
  }

  const createStyle = (className: string, scriptPath: string) => {
    const link = document.createElement("link");
    link.className = className;
    link.href = `${scriptPath}`;
    link.rel = "stylesheet";
    return link;
  }

  const bindScript = (scriptObject: any) => {
    document.head.appendChild(scriptObject)
  }

  const bindStyle = (styleObject: any) => {
    document.head.appendChild(styleObject);
  }

  const bindEntryPoints = (otherEntrypoints: any, className: string, path: string, entrypoint: string, sectionId?: string, jsonData?: string) => {
    const entrypointFiles: string[] = otherEntrypoints[entrypoint];

    addRemoveControlTowerClassOnBody(className);
    entrypointFiles.forEach((filePath) => {
      if (filePath.endsWith(".js")) {
        bindScript(createScript(className, entrypoint, `${path}/${filePath}`, sectionId, jsonData));
      }

      if (filePath.endsWith(".css")) {
        bindStyle(DomUtils.createStyle(className, `${path}/${filePath}`));
      }
    });
  }

  const unMountHtml = (className: string) => {
    document.querySelectorAll(`.${className}`).forEach((o) => {
      if (o.tagName.toUpperCase() === 'SCRIPT' && o.getAttribute("src")) {
        document.dispatchEvent(new CustomEvent(`workspace-unmount-${o.getAttribute("src")}`));
      }
      o && o.remove();
    });
  }

  const getModaMfeContainer = (elementId: string, element: JSX.Element) => {
    return React.createElement('div', { id: elementId, className: 'p-dialog-mask p-dialog-visible p-dialog-draggable p-dialog-resizable single-modal-mask p-dialog-center p-dailog-focus p-dailog-active' }, element);
  }

  // Remove classes from element and set filtered classes
  const removeClassFromEle = (ele: any, classes: string[]) => {
    if (!ele.getAttribute('class')) return;

    let existingClasses: string[] = ele.getAttribute('class').split(' ').filter((v: string) => v !== '');
    let filteredClasses: string[] = existingClasses.filter((v: string) => !classes.includes(v));
    return filteredClasses.join(' ');
  }

  const addOnlySearchClass = (className: string[] = ['im-only-search-modal']) => {
    const activeElement: any = document.querySelector(selector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, [...className, 'im-with-search-modal'])} ${className.join(' ')}`);
  }

  const removeWithSearchClass = (className: string[] = ['im-with-search-modal']) => {
    const activeElement: any = document.querySelector(selector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, className)}`);
  }

  const addWithSearchClass = (className: string[] = ['im-with-search-modal']) => {
    const activeElement: any = document.querySelector(selector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, [...className, 'im-only-search-modal'])} ${className.join(' ')}`);
  }

  const addContainerAmDetailClass = (className: string[] = ['am-container-detail-modal']) => {
    const activeElement: any = document.querySelector(amSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, className)} ${className.join(' ')}`);
  }

  const removeContainerAmDetailClass = (className: string[] = ['am-container-detail-modal']) => {
    const activeElement: any = document.querySelector(amSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, className)}`);
  }

  const addOnlyAmSearchClass = (className: string[] = ['am-only-search-modal']) => {
    const activeElement: any = document.querySelector(amSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, [...className, 'am-with-search-modal'])} ${className.join(' ')}`);
  }

  const removeOnlyAmSearchClass = (className: string[] = ['am-only-search-modal']) => {
    const activeElement: any = document.querySelector(amSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, className)}`);
  }

  const addWithAmSearchClass = (className: string[] = ['am-with-search-modal']) => {
    const activeElement: any = document.querySelector(amSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, [...className, 'am-only-search-modal'])} ${className.join(' ')}`);
  }

  const removeWithAmSearchClass = (className: string[] = ['am-with-search-modal']) => {
    const activeElement: any = document.querySelector(amSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, className)}`);
  }

  const addOnlyDocSearchClass = (className: string[] = ['im-only-search-modal']) => {
    const activeElement: any = document.querySelector(docSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, [...className, 'im-with-search-modal'])} ${className.join(' ')}`);
  }

  const removeWithDocSearchClass = (className: string[] = ['im-with-search-modal']) => {
    const activeElement: any = document.querySelector(docSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, className)}`);
  }

  const addWithDocSearchClass = (className: string[] = ['im-with-search-modal']) => {
    const activeElement: any = document.querySelector(docSelector);
    if (!activeElement) return;

    activeElement.setAttribute('class', `${removeClassFromEle(activeElement, [...className, 'im-only-search-modal'])} ${className.join(' ')}`);
  }

  return {
    mfeContainer,
    getMfeClassName,
    createScript,
    createStyle,
    bindScript,
    bindStyle,
    bindEntryPoints,
    unMountHtml,
    getModaMfeContainer,
    addOnlySearchClass,
    removeWithSearchClass,
    addWithSearchClass,
    addContainerAmDetailClass,
    removeContainerAmDetailClass,
    addOnlyAmSearchClass,
    removeOnlyAmSearchClass,
    addWithAmSearchClass,
    removeWithAmSearchClass,
    addOnlyDocSearchClass,
    removeWithDocSearchClass,
    addWithDocSearchClass,
  }
}

export const DomUtils = handler();