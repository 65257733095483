import { IArpitaModalOption } from "domain/entity/ArpitaTemplateEntity/ArpitaTemplateEntity";

export interface IDialogData {
    dialogId: string;
    isFullScreen: boolean;
    normalLeftPosition: string | undefined;
    normalTopPosition: string | undefined;
    fullscreenLeftPosition: string | undefined;
    fullscreenTopPosition: string | undefined;
    dialogMaskZIndex: number | undefined;
    dialogZIndex: number | undefined;
}

export function makeDialogUtilsData(dialogId: string, isFullScreen: boolean, normalLeftPosition: string | undefined, normalTopPosition: string | undefined, fullscreenLeftPosition: string | undefined, fullscreenTopPosition: string | undefined, dialogMaskZIndex: number | undefined, dialogZIndex: number | undefined): IDialogData {
    return {
        dialogId,
        isFullScreen,
        normalLeftPosition,
        normalTopPosition,
        fullscreenLeftPosition,
        fullscreenTopPosition,
        dialogMaskZIndex,
        dialogZIndex,
    }
}

function bindEventWithModal(elementId: string) {
    let ele: any = document.querySelector(`body.modal > .p-dialog-mask .single-modal-dialog.${elementId}`)?.parentNode;
    if (!ele) ele = document.querySelector(`body.modal > .p-dialog-mask .${elementId}`)?.parentNode;
    if (!ele) return;

    const cb = function (e: any) {
        e.preventDefault && e.preventDefault();
        DialogUtils.removeActiveFocusClass();
        DialogUtils.addFocusActiveClassOnElement(ele);
    }

    ele.removeEventListener('click', cb);
    ele.addEventListener('click', cb);
    cb(ele);
}

function compare(a: any, b: any) {
    const aZindex: number = a.zIndex;
    const bZindex: number = b.zIndex;

    if (aZindex < bZindex) {
        return -1;
    }
    if (aZindex > bZindex) {
        return 1;
    }

    return 0;
}

function handler() {
    let dialogData: IDialogData[] = [];

    // Remove classes from element and set filtered classes
    const removeClassFromEle = (ele: any, classes: string[]) => {
        if (!ele.getAttribute('class')) return;

        let existingClasses: string[] = ele.getAttribute('class').split(' ').filter((v: string) => v !== '');
        let filteredClasses: string[] = existingClasses.filter((v: string) => !classes.includes(v));
        return filteredClasses.join(' ');
    }

    // Iterate all dialog mask and remove active and focus classes
    const removeActiveFocusClass = (removeClases: string[] = ['p-dailog-focus', 'p-dailog-active']) => {
        const ms: any = document.querySelectorAll(`.modal .p-dialog-mask`);
        if (ms.length === 0) return;

        for (let i = 0; i < ms.length; i++) ms[i].setAttribute('class', removeClassFromEle(ms[i], removeClases));
    }

    const generateDialogData = (data: IDialogData) => {
        const dataIndex = dialogData.findIndex((temp: IDialogData) => temp.dialogId.toString().toLowerCase() === data.dialogId.toString().toLowerCase());

        if (dataIndex >= 0) {
            dialogData = [
                ...dialogData.slice(0, dataIndex),
                { ...dialogData[dataIndex], isFullScreen: data.isFullScreen, fullscreenLeftPosition: data.fullscreenLeftPosition, fullscreenTopPosition: data.fullscreenTopPosition, dialogMaskZIndex: data.dialogMaskZIndex, dialogZIndex: data.dialogZIndex },
                ...dialogData.slice(dataIndex + 1),
            ]
        } else {
            dialogData.push(data);
            setTimeout(() => bindEventWithModal(data.dialogId), 300);
        }
    }

    const getElementData = (dialogId: string): IDialogData | undefined => {
        if (dialogData.length > 0) return dialogData.find((temp: IDialogData) => temp.dialogId.toString().toLowerCase() === dialogId.toString().toLowerCase());

        return;
    }

    const getElementStyles = (elementId: string): any | undefined => {
        const activeElement: any = document.querySelector(`body.modal > .p-dialog-mask .single-modal-dialog.${elementId}`);
        if (!activeElement) return;

        return activeElement.style;
    }

    const addFocusActiveClassOnElement = (ele: any) => {
        if (!ele) return;

        ele.setAttribute('class', `${removeClassFromEle(ele, ['p-dailog-focus', 'p-dailog-active'])} p-dailog-focus p-dailog-active`);
    }

    // Make Dialog Active by Element Id
    const resetAllZIndex = () => {
        let initialZIndex: number = 100;
        const ms: any = document.querySelectorAll(`.modal > .p-dialog-mask`);

        const zIndex: object[] = [];
        for (let i = 0; i < ms.length; i++) {
            zIndex.push({ ele: ms[i], zIndex: ms[i].style.zIndex });
        }
        zIndex.sort(compare);

        zIndex.forEach((v: any) => {
            v.ele.style.zIndex = initialZIndex++;
        });

        return {
            lastZIndex: initialZIndex,
            lastElement: zIndex[zIndex.length - 1],
        };
    }

    // Make Dialog Active by Element Id
    const makeFullScreen = (elementId: string | any, isDrag: boolean = false) => {
        let activeElement: any = elementId;
        if (typeof elementId === 'string') activeElement = document.querySelector(`body.modal > .p-dialog-mask .${elementId}`)?.parentNode;

        if (!activeElement) {
            // When dialog is closed
            const aElement: any = resetAllZIndex().lastElement;
            if (!aElement || !aElement.ele) return;

            removeActiveFocusClass();
            addFocusActiveClassOnElement(aElement.ele);
            return;
        }

        activeElement.style.zIndex = resetAllZIndex().lastZIndex;

        removeActiveFocusClass();
        addFocusActiveClassOnElement(activeElement);

        if (isDrag) {
            const data: IDialogData | undefined = getElementData(elementId);
            const aElement: any = document.querySelector(`body.modal > .p-dialog-mask .${elementId}`);
            if (data && aElement) {
                aElement.style.left = data.fullscreenLeftPosition;
                aElement.style.top = data.fullscreenTopPosition;
            }
        }
    }

    const resizeDialog = (elementAttr: any | string) => {
        let element: any = elementAttr;
        if (typeof elementAttr === 'string') element = document.querySelector(`body.modal > .p-dialog-mask .${elementAttr}`);
        else if (!element.getAttribute('class')?.includes('single-modal-dialog')) return;

        const divWidth: number = Number(element.style.width.toString().toLowerCase().replaceAll('px', ''));
        const removeClasses: string[] = ['arpita-xs', 'arpita-sm', 'arpita-lg', 'arpita-xlg'];

        if (divWidth < 590) {
            element.setAttribute('class', `${removeClassFromEle(element, removeClasses)} arpita-xs`);
        } else if (divWidth > 590 && divWidth < 790) {
            element.setAttribute('class', `${removeClassFromEle(element, removeClasses)} arpita-sm`);
        } else if (divWidth > 790 && divWidth < 990) {
            element.setAttribute('class', `${removeClassFromEle(element, removeClasses)} arpita-lg`);
        } else if (divWidth > 990 && divWidth < 1090) {
            element.setAttribute('class', `${removeClassFromEle(element, removeClasses)} arpita-xlg`);
        } else {
            element.setAttribute('class', `${removeClassFromEle(element, removeClasses)}`);
        }

        makeFullScreen(element.parentNode);
    }

    const addRemoveClass = (eleClass: string, removeClassName: string[], addClassName: string[]) => {
        const element: any = document.querySelector(`body.modal > .p-dialog-mask .p-dialog.${eleClass}`);
        if (!element) return;

        element.setAttribute('class', `${removeClassFromEle(element, removeClassName)} ${addClassName.join(' ')}`);
    }

    const getStyleForMask = (defaultIndex: number = 100): number => {
        const msFullScreen: any = document.querySelectorAll(`.modal .p-dialog-mask.p-arpita-modal-fs`);
        const msNormal: any = document.querySelectorAll(`.modal .p-dialog-mask.single-modal-mask:not(.p-arpita-modal-fs)`);
        if (msFullScreen.length > 0) return 500 + msFullScreen.length + 1;
        else if (msNormal.length > 0 && defaultIndex !== 500) return 100 + msNormal.length + 1;
        else return defaultIndex;
    }

    const getDailogStyles = (position: number, modalOptions: IArpitaModalOption | undefined = undefined) => ({
        left: (modalOptions && modalOptions.leftPosition) ? modalOptions.leftPosition : 50 * ((position - 1) + 1) + 'px',
        top: (modalOptions && modalOptions.topPosition) ? modalOptions.topPosition : 50 * ((position - 1) + 1) + 'px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    });

    const removeActiveDialog = (e: any) => {
        let activeElement = e.target;
        let eles = document.querySelectorAll(`body > .p-contextmenu, body .main-container`);

        for (let i = 0; i < eles.length; i++) {
            if (eles[i].contains(activeElement)) {
                const ms: any = document.querySelectorAll(`.modal .p-dialog-mask`);
                if (ms.length === 0) return;

                for (let i = 0; i < ms.length; i++) {
                    const dialogMask = ms[i];
                    const newClass = dialogMask.getAttribute('class').replaceAll('p-dailog-focus', '').replaceAll('p-dailog-active', '').trim();
                    dialogMask.setAttribute('class', newClass);
                }
                return false;
            }
        }
    }

    const addRemoveModalClassOnBody = () => {
        let cls: string = document.body.getAttribute('class') || '';
        cls = cls.replaceAll('modal', '');

        const ms: any = document.querySelectorAll(`body>.p-dialog-mask`);
        if (ms.length > 0) cls = `${cls ? cls + ' ' : ''}modal`;

        document.body.setAttribute('class', cls);
    }

    return {
        get: getElementData,
        resizeDialog: resizeDialog,
        getElementStyles: getElementStyles,
        set: (modalId: string, isFullScreen: boolean | undefined, normalLeftPosition: string | undefined, normalTopPosition: string | undefined, fullscreenLeftPosition: string | undefined, fullscreenTopPosition: string | undefined, dialogMaskZIndex: number | undefined, dialogZIndex: number | undefined) => {
            if (isFullScreen && typeof isFullScreen === 'boolean') generateDialogData(makeDialogUtilsData(modalId, isFullScreen, normalLeftPosition, normalTopPosition, fullscreenLeftPosition, fullscreenTopPosition, dialogMaskZIndex, dialogZIndex));
            else {
                const activeElement: any = document.querySelector(`body.modal > .p-dialog-mask .${modalId}`);
                if (!activeElement) return;

                generateDialogData(makeDialogUtilsData(modalId, true, '', '', activeElement.style.left, activeElement.style.top, activeElement.parentNode.style.zIndex, activeElement.style.zIndex))
            }
        },
        removeActiveFocusClass: () => removeActiveFocusClass(),
        addFocusActiveClassOnElement: (ele: any) => addFocusActiveClassOnElement(ele),
        makeFullScreen: makeFullScreen,
        addRemoveClass: addRemoveClass,
        removeContentClass: () => {
            setTimeout(() => {
                const ms: any = document.querySelectorAll(`body.modal > .p-dialog-mask .single-modal-dialog>.p-dialog-content`);
                if (ms.length === 0) return;

                for (let i = 0; i < ms.length; i++) ms[i].setAttribute('class', removeClassFromEle(ms[i], ['p-dialog-content']));
            }, 0);
        },
        getStyleForMask,
        getDailogStyles,
        removeActiveDialog,
        addRemoveModalClassOnBody,
    };
}

export const DialogUtils = handler();