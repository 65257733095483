import { WorkspaceModel } from "presentation/model/workspaceModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";

interface MainViewVMProps extends BaseViewModel {
    dispatch: [
            Dispatch<SetStateAction<WorkspaceModel>> | ((value: SetStateAction<WorkspaceModel>) => void),
    ]
}

const MainViewVM = ({dispatch}: MainViewVMProps) => {
    const [workspaceDispatch] = dispatch
    //TODO: should not use any... but change later if have time.
    const onCloseMFE = (e: any, userName: string) => {
        workspaceDispatch(prevState => ({
            ...prevState,
            showMainView: false,
            showNavigation: true,
            miniToolbarArr: [],
            supportingModulesList: [],
            isSupportingModules: false,
            expanded: true,
            workspaceDropdown: "",
        }))
    };

    const setPageInformation = (mfePageInformation: any) => {
        workspaceDispatch(prevState => ({
            ...prevState,
            mfePageInformation: mfePageInformation
        }))
    }

    return {
        onCloseMFE,
        setPageInformation
    };
};

export default MainViewVM;