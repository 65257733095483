import {useState} from "react";
import {EMPTY_WORKSPACE_MODEL, StoredWorkspaceModel} from "presentation/model/workspaceModel";
import {createContainer} from "react-tracked";

const sessionStorageState = sessionStorage.getItem('workspace-state')
const storedWorkspaceState: StoredWorkspaceModel = sessionStorageState ? JSON.parse(sessionStorageState) as StoredWorkspaceModel : EMPTY_WORKSPACE_MODEL;
const {
    Provider: WorkspaceProvider,
    useTracked: useWorkspaceTracked
} = createContainer(() => useState({...EMPTY_WORKSPACE_MODEL, ...storedWorkspaceState}), {concurrentMode: true});
export {WorkspaceProvider, useWorkspaceTracked};