import { AlessiaRepo } from "./AlessiaRepo";

export const AlessiaRepoImpl = (token?: string): AlessiaRepo => {
    const backendContextPath = '/alessia-mgmt-portal-backend/api';

    const setDefaultBu = (bu: string, email: string) => {
        fetch(`${backendContextPath}/user/updateUserDefaultBu`, {
            method: "POST",
            body: JSON.stringify({
                defaultBu: bu,
                username: email
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
    };

    return {
        setDefaultBu: setDefaultBu,
    };
};
