/* eslint-disable react-hooks/exhaustive-deps */
import { ArpitaTemplateEntity, E_ArpitaHandlerAction } from "domain/entity/ArpitaTemplateEntity/ArpitaTemplateEntity";
import { IVersionInformation } from "domain/entity/EnvironmentVariables";
import { ArpitaRepoImpl } from "domain/repository/Arpita/ArpitaRepoImpl";
import { BaseNavigationRepositoryImpl } from "domain/repository/Navigation/BaseNavigationRepositoryImpl";
import { DomUtils } from "helpers/DomUtils";
import { E_ArpitaOperation, E_Custom_Dispatch_Event, E_Type_Of_Event, customEventListener } from "helpers/Events";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import VersionSvg from 'presentation/images/brand-logo.svg';
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import { IModal, Modal } from "presentation/view/components/workspace/Modal/Modal";
import { E_Notification_Type } from "presentation/view/components/workspace/Notification/Notification";
import NavigationMenuVM from "presentation/viewModel/workspace/NavigationMenuVM/NavigationMenuVM";
import NotificationVM from "presentation/viewModel/workspace/NotificationVM/NotificationVM";
import { useCallback, useEffect, useMemo, useState } from "react";

interface IModalMainView {
  updateArpitaMenu: () => void;
}

export const ModalMainView: React.FC<IModalMainView> = (props: IModalMainView) => {
  const { updateArpitaMenu } = props;
  const [customModal, setCustomModal] = useState<{ [x: string]: IModal }>({});
  const [workspaceState, setWorkspaceState] = useWorkspaceTracked();
  const { token, currentBu } = useANAInfo();
  const { showChargeSearch, showDocumentSearch, showDocumentItemSearch, showArpitaTemplate, showModalForVersionInformation, 
    showModalForVersionIcon, openArpitaTemplates, arpitaFocusTemplate, environmentVariable } = workspaceState;
  const navigationMenuVM = useMemo(() => NavigationMenuVM({ dispatch: [setWorkspaceState], arpitaRepo: ArpitaRepoImpl() }), []);
  const notificationVM = useMemo(() => NotificationVM({ dispatch: [setWorkspaceState], }), []);

  const handleModalClose = useCallback((obj: { [x: string]: boolean }) => {
    setWorkspaceState(prevState => ({ ...prevState, ...obj }));
  }, []);

  const handleModalFullScreen = (data: any)=>{
    const ele: Element | null = document.querySelector(`body.modal > .p-dialog-mask .${data.className}`);
        if (!ele) return;

        if (data.isFullScreen) {
          ele?.setAttribute('class', ele?.getAttribute('class')?.toString().replaceAll('minimise', 'enlarge') || '');
        } else {
          ele?.setAttribute('class', ele?.getAttribute('class')?.toString().replaceAll('enlarge', 'minimise') || '');
        }
  }

  const versionModalContent: JSX.Element = useMemo(() => {
    if (!environmentVariable.version) return <></>;

    return <>
      <div className="version-logo"><img alt="Version Information" src={VersionSvg} /></div>
      <div className="version-title">{currentBu}</div>
      <div className="version-box">
        {environmentVariable.version.map((version: IVersionInformation) => <label key={version.appName}><span>{version.appName}</span><span>{version.version}</span></label>)}
      </div>
    </>
  }, [environmentVariable]);



  const modalData: { [x: string]: IModal } = useMemo(() => {
    const navigation = new BaseNavigationRepositoryImpl();
    const data: { [x: string]: IModal } = {
      // charge search
      'charge-search-modal': {
        isVisible: showChargeSearch,
        showExistingHeader: false,
        modalTitle: 'CHARGE SEARCH',
        dailogClass: 'charge-search-modal im-only-search-modal',
        //modalHeight: "507px",
        enteryPointData: navigation.chargeSearch[0],
        onClose: () => handleModalClose({ showChargeSearch: false }),
      },
      // document Search
      'document-search-modal': {
        isVisible: showDocumentSearch,
        showExistingHeader: false,
        modalTitle: 'DOCUMENT SEARCH',
        dailogClass: 'document-search-modal im-only-search-modal',
        enteryPointData: navigation.documentSearch[0],
        onClose: () => handleModalClose({ showDocumentSearch: false }),
      },
      // document item Search
      'document-item-search-modal': {
        isVisible: showDocumentItemSearch,
        showExistingHeader: false,
        modalTitle: 'MOVEMENT SEARCH',
        dailogClass: 'document-item-search-modal am-only-search-modal',
        enteryPointData: navigation.documentItemSearch[0],
        onClose: () => handleModalClose({ showDocumentItemSearch: false }),
      },
      // Arpita template
      'version-information-modal': {
        isVisible: showModalForVersionInformation,
        showExistingHeader: true,
        modalTitle: <span className="md-header-title" style={{ flexGrow: '1' }}><span className="template-list"></span></span>,
        dailogClass: 'version-information-modal',
        maskClass: 'version-information-modal-mask',
        enteryPointData: undefined,
        resizable: false,
        modalContent: versionModalContent,
        showMaximiseButton:false,
        showMinimiseButton: false,
        onClose: () => handleModalClose({ showModalForVersionInformation: false }),
        onMinimiseCallbackOnly: () => setWorkspaceState(prevState => ({ ...prevState, showModalForVersionIcon: true, showModalForVersionInformation: false })),
      },
      ...((Object.keys(customModal).length > 0) ? { ...customModal } : {})
    }

    // Arpita template
    if (showArpitaTemplate && openArpitaTemplates && Object.keys(openArpitaTemplates).length > 0) {
      Object.keys(openArpitaTemplates).forEach((templateId: string) => {
        if (!data[`arpita-template-modal-${templateId}`] && openArpitaTemplates[templateId]) {
          data[`arpita-template-modal-${templateId}`] = {
            isVisible: showArpitaTemplate,
            showExistingHeader: true,
            modalTitle: 'TEMPLATE',
            dailogClass: `arpita-template-modal ${workspaceState?.navigationBarPinned ? 'pined-enlarge' : ''}`,
            modalHeight: "700px",
            showMaximiseButton: true,
            enteryPointData: { ...navigation.arpitaTemplate[0], sectionId: openArpitaTemplates[templateId].openTId },
            openArpitaTemplate: openArpitaTemplates[templateId],
            focusModalId: DomUtils.mfeContainer('arpitaTemplate', arpitaFocusTemplate),
            onArpitaClose: navigationMenuVM.arpitaDailogHandler,
          };
        } else data[`arpita-template-modal-${templateId}`] = { ...data[`arpita-template-modal-${templateId}`], openArpitaTemplate: openArpitaTemplates[templateId], };
      })
    }

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChargeSearch, showDocumentSearch, showDocumentItemSearch, showArpitaTemplate, showModalForVersionInformation, 
    showModalForVersionIcon, openArpitaTemplates, customModal, arpitaFocusTemplate]);

  const closeCustomModal = (modalKey: string) => {
    setCustomModal(p => {
      const n = { ...p };
      delete n[modalKey];
      return { ...n, }
    });
  }

  const oepnNewModal = (data: any) => {
    setCustomModal(p => ({
      ...p,
      [data.modalKey]: {
        isVisible: true,
        showExistingHeader: data.showExistingHeader,
        modalTitle: 'Hello World' + data.enteryPoint,
        dailogClass: '',
        maskClass: data.maskClass,
        modalHeight: modalData.modalHeight, // Height should be in pixel or percent
        enteryPointData: { path: data.path, entrypoint: data.enteryPoint },
        resizable: data.resizable || true,
        draggable: data.resizable || true,
        modalContent: data.modalContent,
        showMaximiseButton: data.showMaximiseButton,
        showMinimiseButton: data.showMinimiseButton,
        onClose: () => closeCustomModal(data.modalKey),
        onMinimise: (e: React.MouseEvent<HTMLAnchorElement>) => data.onMinimise,
        onMinimiseCallbackOnly: (e: React.MouseEvent<HTMLAnchorElement>) => data.onMinimiseCallbackOnly,
      }
    }));
  }

  const onModalEventChange = (e: any) => {
    switch (e.detail.action) {
      case E_Custom_Dispatch_Event.MODAL_OPEN:
        oepnNewModal(e.detail.data);
        break
      case E_Custom_Dispatch_Event.MODAL_CLOSE:
        handleModalClose(e.detail.data);
        break;
      case E_Custom_Dispatch_Event.MODAL_FULL_SCREEN_MODE:
        handleModalFullScreen(e.detail.data);
        break;        
    }
  }

  const onArpitaTemplateChange = (e: any) => {
    switch (e.detail.action) {
      case E_Custom_Dispatch_Event.SAVE_UPDATE_TEMPLATE:
        navigationMenuVM.arpitaDailogHandler(token, e, E_ArpitaHandlerAction.CREATE_NEW_TEMPLATE, e.detail.data.id, e.detail.data.oTemplate, e.detail.data, updateArpitaMenu);
        break;
      case E_Custom_Dispatch_Event.OPEN_FROM_MORE:
        const template: ArpitaTemplateEntity | undefined = workspaceState.arpitaTemplates.find((t: ArpitaTemplateEntity) => t.id === e.detail.data.templateId);
        navigationMenuVM.arpitaDailogHandler(token, e, E_ArpitaHandlerAction.OPEN_FROM_MORE, e.detail.data.templateId, template);
        break;
      case E_Custom_Dispatch_Event.ARPITA_CREATE_DATATABLE:
        const t: ArpitaTemplateEntity = { ...e.detail.data.template, id: E_ArpitaOperation.NEW_DATATABLE };
        navigationMenuVM.arpitaDailogHandler(token, e, E_ArpitaHandlerAction.CREATE_DATATABLE, t.id, t, e.detail.data.data);
        break;
      case E_Custom_Dispatch_Event.ARPITA_CREATE_TEMPLATE_FROM_SELECTTION:
        const t1: ArpitaTemplateEntity = { ...e.detail.data.template, id: E_ArpitaOperation.NEW_TEMPLATE_FROM_SELECTION };
        navigationMenuVM.arpitaDailogHandler(token, e, E_ArpitaHandlerAction.CREATE_TEMPLATE_FROM_SELECTION, t1.id, t1, e.detail.data.data);
        break;
    }
  }

  const onChargeDataSearchChange = (e: any) => {
    switch (e.detail.action) {
  //     // case E_Custom_Dispatch_Event.INVENTORY_MODAL_CLOSE:
  //     //   break;
    }
  }

  const onMovementSearchChange = (e: any) => {
    switch (e.detail.action) {
      // case E_Custom_Dispatch_Event.MOVEMENT_MODAL_CLOSE:
      //   break;
    }
  }

  const onNotificationMessageChange = (e: any) => {
    switch (e.detail.action) {
      case E_Custom_Dispatch_Event.NOTIFICATION_MESSAGE_SHOW:
        notificationVM.showNotification(e.detail.data.isSuccess ? E_Notification_Type.SUCCESS : E_Notification_Type.ALERT, e.detail.data.message);
        break;
    }
  }

  const onDocumentEnquiryChange = (e: any) => {
    switch (e.detail.action) {
  //     // case E_Custom_Dispatch_Event.INVENTORY_MODAL_CLOSE:
  //     //   break;
    }
  }


  useEffect(() => {
    customEventListener(E_Type_Of_Event.MODAL_EVENT, onModalEventChange);
    customEventListener(E_Type_Of_Event.ARPITA_EVENT, onArpitaTemplateChange);
    customEventListener(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, onChargeDataSearchChange);
    customEventListener(E_Type_Of_Event.DOCUMENT_SEARCH_EVENT, onMovementSearchChange);
    customEventListener(E_Type_Of_Event.NOTIFICATION_MESSAGE_EVENT, onNotificationMessageChange);;
    customEventListener(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, onDocumentEnquiryChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{Object.keys(modalData).map((modalKey: string) => <Modal key={modalKey} {...modalData[modalKey]} />)}</>;
}