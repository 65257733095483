import BaseViewModel from "../../BaseViewModel";
import { AlessiaRepo } from "domain/repository/Alessia/AlessiaRepo";

interface MinitoolBarVMProps extends BaseViewModel {
    dispatch: [];
    AlessiaRepo: AlessiaRepo;
}

const MinitoolBarVM = ({ dispatch, AlessiaRepo }: MinitoolBarVMProps) => {
    const setDefaultBu = (bu: string, email: string) => {
        //javascript console log accept any type as input. convent to string in case there's anything special...
        //first line is the above error. second line is stack of this function...
        AlessiaRepo.setDefaultBu(bu, email)
    };

    return {
        setDefaultBu: setDefaultBu,
    };
};

export default MinitoolBarVM;
