export interface EnvironmentVariableEntity {
    env: string;
    elasticApmEnabled: boolean
    elasticApmServerUrl?: string
    version?: IVersionInformation[];
}

export interface IVersionInformation {
    appName: string;
    version: string;
}

export const EMPTY_ENVIRONMENT_VARIABLE_ENTITY: EnvironmentVariableEntity = {
    env: "",
    elasticApmEnabled: false,
}