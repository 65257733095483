import {useEffect} from "react";
import {useWorkspaceTracked} from "../store/NavigationMenu/NavigationMenu";
import {init} from '@elastic/apm-rum';

export const useInitApm = () => {
    const [workspaceState] = useWorkspaceTracked()
    useEffect(() => {
        if (workspaceState.environmentVariable.elasticApmEnabled && workspaceState.environmentVariable.elasticApmServerUrl) {
            init({
                serviceName: 'workspace-frontend',
                serverUrl: workspaceState.environmentVariable.elasticApmServerUrl,
                distributedTracingOrigins: [],
                propagateTracestate: true,
                active: workspaceState.environmentVariable.elasticApmEnabled
            });
        }

    }, [workspaceState.environmentVariable.elasticApmEnabled, workspaceState.environmentVariable.elasticApmServerUrl])
};
