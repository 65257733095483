import { useEffect, useMemo } from "react";
import {
    FrontendConsoleTrackingRepoImpl
} from "domain/repository/FrontendConsoleTracking/FrontendConsoleTrackingRepoImpl";
import { FrontendConsoleType } from "presentation/constant/FrontendConsoleType";
import FrontendConsoleTrackingVM from "presentation/viewModel/workspace/FrontendConsoleTracking/FrontendConsoleTrackingVM";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";

export const useFrontendConsoleTracking = (identifier: string) => {
    const { email } = useANAInfo()
    const frontendConsoleTrackingVM = useMemo(() =>
        FrontendConsoleTrackingVM({
            dispatch: [],
            frontendConsoleTrackingRepo: FrontendConsoleTrackingRepoImpl()
        }), []
    )
    useEffect(() => {
        //skip log tracking in development environment in order to debug easily.
        if (process.env.NODE_ENV === 'development') {
            return () => {
            }
        }
        //Save the original console log, so we can still use it
        const originalConsoleError = console.error
        //Override console to our custom function.
        console.error = (error: any) => {
            try {
                frontendConsoleTrackingVM.uploadFrontendConsoleTracking(email, identifier, FrontendConsoleType.ERROR, error?.toString() ?? 'null')
            } catch (errorInCustomConsoleConsoleFunction) {
                //prevent custom function break the original javascript console.error... in case if there's any bug. The console error will still print.
                originalConsoleError(errorInCustomConsoleConsoleFunction)
            }
            //print the original log in FE
            originalConsoleError(error)
        }
        return () => {
            console.error = originalConsoleError
        }
    }, [email, frontendConsoleTrackingVM, identifier])
}