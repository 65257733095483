import BaseViewModel from "presentation/viewModel/BaseViewModel";
import {newFrontendConsoleTracking} from "domain/entity/UserTracking/FrontendConsoleTrackingEntity";
import {
    FrontendConsoleTrackingRepo
} from "domain/repository/FrontendConsoleTracking/FrontendConsoleTrackingRepo";
import {FrontendConsoleType} from "presentation/constant/FrontendConsoleType";

interface FrontendConsoleTrackingVMProps extends BaseViewModel {
    dispatch: [];
    frontendConsoleTrackingRepo: FrontendConsoleTrackingRepo;
}

const removeFirstLineOfString = (str?: string) => {
    return str?.substring(str?.indexOf("\n") + 1)
}

const FrontendConsoleTrackingVM = ({dispatch, frontendConsoleTrackingRepo}: FrontendConsoleTrackingVMProps) => {
    const uploadFrontendConsoleTracking = (userEmail: string, identifier: string, type: FrontendConsoleType, message: string) => {
        const err = new Error();
        //javascript console log accept any type as input. convent to string in case there's anything special...
        //first line is the above error. second line is stack of this function...
        frontendConsoleTrackingRepo.createNewFrontendConsoleTracking(newFrontendConsoleTracking(userEmail, identifier, type, message, removeFirstLineOfString(removeFirstLineOfString(err.stack)) ?? ''))
    };

    return {
        uploadFrontendConsoleTracking: uploadFrontendConsoleTracking,
    };
};

export default FrontendConsoleTrackingVM;
