export interface IUnderConstruction {
  text: string;
}

export const UnderConstruction: React.FC<IUnderConstruction> = ({ text }: IUnderConstruction) => {
  return <div className="under-construction">
    <div className="inner-wrapper">
      <i className="icon-alert-circle-fill"></i>
      <p>{text}</p>
    </div>   
  </div>;
}