import {
    IMenuEntity,
    ModalNavigationEntity,
    NavigationAllEntity,
    NavigationEntity,
    SubNavigationEntity
} from "domain/entity/Navigation";
import { NavigationRepository } from "./NavigationRepository";

function getAllMenuNames(menus: NavigationEntity[]): string[] {
    const menuNames: string[] = [];
    menus.forEach((menu: NavigationEntity) => {
        menuNames.push(menu.title || '');
        if (menu.subMenuMap && menu.subMenuMap !== '') menuNames.push(menu.subMenuMap);

        if (menu.title?.toLowerCase() === 'berth' || menu.title?.toLowerCase() === 'setting') {
            menu.submenu && menu.submenu.length > 0 && menu.submenu.forEach((m: NavigationEntity) => {
                menuNames.push(m.title || '');
            });
        }
    });
    return menuNames;
}

function getSubNavigationListWithLabel(menus: NavigationEntity[]): NavigationEntity[] {
    return menus.map((menu: NavigationEntity) => ({ ...menu, label: menu.title, customTitle: menu.title }));
}

function makeSubNavMenu(mainMenus: NavigationEntity[] | undefined): SubNavigationEntity[] {
    let subMenu: SubNavigationEntity[] = [];
    let isSubMenuAdded: { [x: string]: boolean } = {};

    function selfMakeSubNavMenu(menus: NavigationEntity[]): void {
        // Get all menu names from navigation menu
        const menuNames: string[] = getAllMenuNames(menus);

        // Iterate all menu items
        menus.forEach((menu: NavigationEntity) => {
            // Iterate all menu names
            menuNames.forEach((menuName: string) => {
                // Get title of current menu item
                const title = menu.title?.toLowerCase().replaceAll(' ', '') || '';
                const newSubMenu: SubNavigationEntity = { ...menu };
                if (menu.submenu && menu.submenu.length > 0) {
                    newSubMenu.navigationList = [...getSubNavigationListWithLabel(menu.submenu)]
                }
                subMenu.push({ menuName: menuName, ...newSubMenu });
                if (menu.title?.toLowerCase() !== 'berth' && menu.title?.toLowerCase() !== 'setting' && menu.submenu && menu.submenu.length > 0 && !isSubMenuAdded[title]) {
                    isSubMenuAdded[title] = true;
                    selfMakeSubNavMenu(menu.submenu);
                }
            });
        });
    };

    mainMenus && selfMakeSubNavMenu(mainMenus);

    return subMenu;
}

export class BaseNavigationRepositoryImpl implements NavigationRepository {
    berthMenu: NavigationEntity[] = [];

    getRedirectMenuItems(): NavigationEntity[] {
        return [
            {
                title: "Charge Process",
                icon: "Icon-asc-menu",
                path: "",
                entrypoint: "chargeDetailMaintenance",
                fullscreen: true,
                graphical: false,
            }
        ]
    }

    chargeSearch: ModalNavigationEntity[] = [
        {
            path: "",
            entrypoint: "chargeDataEnquiry"
        }
    ];

    documentSearch: ModalNavigationEntity[] = [
        {
            path: "",
            entrypoint: "documentEnquiry"
        }
    ];

    documentItemSearch: ModalNavigationEntity[] = [
        {
            path: "",
            entrypoint: "documentItemSearch"
        }
    ];

    arpitaTemplate: ModalNavigationEntity[] = [
        {
            path: "",
            entrypoint: "arpitaTemplate"
        }
    ];

    changePassword: ModalNavigationEntity[] = [
        {
            path: "",
            entrypoint: "changePassword"
        }
    ];

    navigation: NavigationEntity[] = [];

    subNavigation: SubNavigationEntity[] = [];

    // Make Navigation
    makeNavigation(): void {
        this.navigation = [];

        // System
        this.navigation.push(...this.getRedirectMenuItems());
    }

    // Make Sub Navigation
    makeSubNavigation(): void {
        this.subNavigation = [];
    }

    removeSettingMenu(menus: NavigationEntity[] | undefined): NavigationEntity[] | undefined {
        return menus && menus.filter((menu: NavigationEntity) => menu.title?.toString().toLowerCase() !== 'setting');
    }

    // Make Sub Navigation
    generateSubNavigation(menus: NavigationEntity[] | undefined): SubNavigationEntity[] {
        const subNavigation: SubNavigationEntity[] = [];

        menus && menus.forEach((navigation: NavigationEntity) => {
            if (navigation.type?.toString().toLowerCase() !== 'group' && navigation.submenu?.length) {
                if (navigation.title?.toString().toLowerCase() === 'setting' && navigation.submenu?.length) {

                    let menuName: string = '';
                    let submenu = navigation.submenu[0];

                    if (['type group'].includes((submenu.title ? submenu.title : "").toString().toLowerCase())) {
                        menuName = 'Container Type';
                    } else if (['country', 'province'].includes((submenu.title ? submenu.title : "").toString().toLowerCase())) {
                        menuName = 'Port Code';
                    }
                    subNavigation.push({ separator: true, menuName }, ...makeSubNavMenu([{ ...navigation, icon: navigation.submenu && navigation.submenu[0].icon, subMenuMap: menuName }]));
                } else {
                    subNavigation.push(...makeSubNavMenu(navigation.submenu));
                }
            }
        })

        return subNavigation;
    }

    // Navigation
    getNavigation(mainMenu: IMenuEntity): NavigationAllEntity {
        return {
            navigation: this.navigation,
            subNavigation: this.subNavigation,
            chargeSearch: this.chargeSearch,
            documentSearch: this.documentSearch,
            documentItemSearch: this.documentItemSearch,
            arpitaTemplate: this.arpitaTemplate,
        };
    }
}