import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { newUserActivityMFEAccess } from "domain/entity/UserTracking/UserActivityMFEAccessEntity";
import {
    UserActivityMFEAccessRepoImpl
} from "domain/repository/UserActivityMFEAccess/UserActivityMFEAccessRepoImpl";
import PageActiveVM from "presentation/viewModel/workspace/PageActiveVM/PageActiveVM";
import { useANAInfo } from "./ANAInfo/useANAInfo";

export const usePageActive = (entrypoint: string, identifier: string) => {
    const pageActiveVM = useMemo(() =>
        PageActiveVM({
            dispatch: [],
            userActivityMFEAccessRepo: UserActivityMFEAccessRepoImpl()
        }), []
    )
    const [visibilityState, setVisibilityState] = useState(true);
    const [visibilityCounter, setVisibilityCounter] = useState(0);
    const [accessDateFrom] = useState(new Date());
    const visibilityCounterRef = useRef(0)
    const userEmail = useANAInfo().email;
    const userEmailRef = useRef(userEmail)
    useEffect(() => {
        userEmailRef.current = userEmail
    })
    useEffect(() => {
        visibilityCounterRef.current = visibilityCounter
    })
    const handleVisibilityChange = useCallback(() => {
        setVisibilityState(document.visibilityState === 'visible');
    }, []);

    useEffect(() => {
        const setVisibilityTrue = () => setVisibilityState(true)
        const setVisibilityFalse = () => setVisibilityState(false)
        document.addEventListener("visibilitychange", handleVisibilityChange)
        window.addEventListener("blur", setVisibilityFalse)
        window.addEventListener("focus", setVisibilityTrue)
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange)
            window.removeEventListener("blur", setVisibilityFalse)
            window.removeEventListener("focus", setVisibilityTrue)
        }
    }, [setVisibilityState, handleVisibilityChange]);


    useEffect(() => {
        if (visibilityState) {
            const addCounter = setInterval(() => {
                setVisibilityCounter(prevState => Number((prevState + 0.01).toFixed(2)))
            },
                10
            )
            return () => {
                clearInterval(addCounter)
            }
        }
        //If modal is not active, shouldn't keep adding counter ,so we should clear previous interval by refreshing useEffect.
    }, [visibilityState, entrypoint]);

    useEffect(() => {
        const cleanup = () => {
            if (visibilityCounterRef.current && visibilityCounterRef.current > 0) {
                pageActiveVM.uploadUserActivityMFEAccess(newUserActivityMFEAccess(entrypoint, visibilityCounterRef.current, userEmailRef.current, identifier, accessDateFrom))
            }
            setVisibilityCounter(0)
        }
        window.addEventListener('beforeunload', cleanup, { once: true }); // It's for closing tab. Not duplicate
        return () => {
            cleanup()
            window.removeEventListener('beforeunload', cleanup);
        }
    }, [entrypoint, accessDateFrom, identifier, pageActiveVM])
}