import { IMenuEntity, NavigationAllEntity } from "domain/entity/Navigation";
import { NavigationRepository } from "domain/repository/Navigation";
import { BaseNavigationRepositoryImpl } from "./BaseNavigationRepositoryImpl";


export class NavigationRepositoryImpl extends BaseNavigationRepositoryImpl implements NavigationRepository {
    getNavigation(mainMenu: IMenuEntity): NavigationAllEntity {
        const newMainMenu: any = mainMenu;
        if (mainMenu) {
            return {
                navigation: this.removeSettingMenu(newMainMenu.navigation),
                subNavigation: this.generateSubNavigation(newMainMenu.navigation),
                chargeSearch: newMainMenu.chargeSearch,
                documentSearch: newMainMenu.documentSearch,
                documentItemSearch: newMainMenu.documentItemSearch,
                // Update with mainMenu.arpitaTemplate when workspace menu API data udpate
                arpitaTemplate: newMainMenu.arpitaTemplate ? newMainMenu.arpitaTemplate : [
                    {
                        path: "arpita",
                        entrypoint: "arpitaTemplate"
                    }
                ],
            };
        }

        return {
            navigation: this.navigation,
            subNavigation: this.subNavigation,
            chargeSearch: this.chargeSearch,
            documentSearch: this.documentSearch,
            documentItemSearch: this.documentItemSearch,
            arpitaTemplate: this.arpitaTemplate,
        };
    }
}