import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { newUserActivityLoginTracking } from "../../domain/entity/UserTracking/UserActivityLoginTrackingEntity";
import {
    UserActivityLoginTrackingRepoImpl
} from "../../domain/repository/UserActivityLoginTracking/UserActivityLoginTrackingRepoImpl";
import LoginTrackingVM from "presentation/viewModel/workspace/LoginTrackingVM/LoginTrackingVM";
import { useANAInfo } from "./ANAInfo/useANAInfo";
import { usePrevious } from "./usePrevious";

interface GeoLocation {
    latitude?: string
    longitude?: string
}

export const useLoginTracking = (identifier: string) => {
    const userEmail = useANAInfo().email
    const previousUsername = usePrevious(userEmail)//when logout, username will be set as "", this is to track username before click logout
    const token = useANAInfo().token
    const [geoLocation, setGeoLocation] = useState<GeoLocation>({})
    const [geoPermissionAllowed, setGeoPermissionAllowed] = useState<boolean | undefined>(undefined)
    const loginTrackingVM = useMemo(() =>
        LoginTrackingVM({
            dispatch: [],
            userActivityLoginTrackingRepo: UserActivityLoginTrackingRepoImpl()
        }), []
    )

    useEffect(() => {
        if (!_.isEmpty(userEmail) && !_.isEmpty(token) && !_.isEmpty(geoLocation.latitude) && !_.isEmpty(geoLocation.longitude)) {
            loginTrackingVM.uploadUserLoginTracking(newUserActivityLoginTracking(userEmail, identifier, geoLocation.latitude!!, geoLocation.longitude!!, token!!, true))
        }
    }, [userEmail, token, geoLocation, identifier, loginTrackingVM])

    useEffect(() => {
        if (geoPermissionAllowed) {
            const setPositionInfo: PositionCallback = (position: GeolocationPosition) => {
                setGeoLocation({
                    latitude: position.coords.latitude.toString(),
                    longitude: position.coords.longitude.toString()
                })
            }
            navigator.geolocation.getCurrentPosition(setPositionInfo)
        } else if (geoPermissionAllowed === false) {
            setGeoLocation({ latitude: '0', longitude: '0' })
        }
    }, [geoPermissionAllowed])

    useEffect(() => {
        /*
            this code is necessary...
            since callback inside getCurrentPosition will never be called without permission,
            we need to set geolocation to all 0 so that we can still log some user data.
            However, if we don't call any getCurrentPosition when permission is not granted. Browser will never ask user for permission so it will always be denied.
      */
        navigator.geolocation.getCurrentPosition(() => {
        })
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                setGeoPermissionAllowed(true)
            } else {
                setGeoPermissionAllowed(false)
            }
        })
    }, [])

    useEffect(() => {
        const cleanup = () => {
            loginTrackingVM.uploadUserLoginTracking(newUserActivityLoginTracking(_.isEmpty(userEmail) ? (previousUsername ?? '') : userEmail, identifier, geoLocation.latitude!!, geoLocation.longitude!!, token!!, false))
        }
        window.addEventListener('beforeunload', cleanup, { once: true }); // It's for closing tab. Not duplicate
        return () => {
            window.removeEventListener('beforeunload', cleanup);
            // Your code here.
        }
    }, [previousUsername, userEmail, token, geoLocation, identifier, loginTrackingVM])
}