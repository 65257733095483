import API from "../API";

export const RemoteDataService = {
  getData(path: any, config = {}) {
    return API.get(path, config);
  },

  postData(path: any, data: any, config = {}) {
    return API.post(path, data, config);
  },

  putData(path: any, data: any, config = {}) {
    return API.put(path, data, config);
  },

  patchData(path: any, data: any, config = {}) {
    return API.patch(path, data, config);
  },

  deleteData(path: any, config = {}) {
    return API.delete(path, config);
  },
};
