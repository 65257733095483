export interface UserActivityMFEAccessEntity {
    entryPoint: string;
    timeSpent: number;
    userEmail: string;
    identifier: string;
    accessDateFrom: Date,
    accessDateTo: Date,
}

export const EMPTY_USER_ACTIVITY_MFE_ACCESS_ENTITY: UserActivityMFEAccessEntity = {
    entryPoint: "",
    timeSpent: 0,
    userEmail: "",
    identifier: '',
    accessDateFrom: new Date(),
    accessDateTo: new Date(),
};

export const newUserActivityMFEAccess = (entrypoint: string, timeSpent: number, userEmail: string, identifier: string, accessDateFrom: Date): UserActivityMFEAccessEntity => {
    return {
        ...EMPTY_USER_ACTIVITY_MFE_ACCESS_ENTITY,
        entryPoint: entrypoint,
        timeSpent: timeSpent,
        userEmail: userEmail,
        identifier: identifier,
        accessDateFrom: accessDateFrom,
        accessDateTo: new Date(),
    };
};
