// import { ApiPath } from "constants/ApiPath";
import { ArpitaRepo } from "./ArpitaRepo";
import { ArpitaTemplateEntity } from "domain/entity/ArpitaTemplateEntity/ArpitaTemplateEntity";

export const ArpitaRepoImpl = (): ArpitaRepo => {
    const pinTemplate = async (templateId: string, pinned: boolean): Promise<string> => {
        // const result = arpitaAxiosInstance.post(`${ApiPath.PATH.ARPITA_SAVE_ALL_TEMPLATE}`, JSON.stringify({ id: templateId, pinned, partial: true }), {
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }
        // })
        //     .then(res => {
        //         return res.data
        //     }).catch(error => {
        //         return null
        //     })
        // return result
        return "";
    }

    const getAllId = async (): Promise<any> => {
        // const result = arpitaAxiosInstance.get(`${ApiPath.PATH.ARPITA_GET_ALL_TEMPLATE_BY_USER}`, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }
        // })
        //     .then(res => {
        //         return res.data
        //     }).catch(error => {
        //         return null
        //     })
        // return result
        const items:ArpitaTemplateEntity[] = [];
        return items;
    }

    return {
        pinTemplate: pinTemplate,
        getAllId: getAllId,

    }
}