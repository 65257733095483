import { ArpitaTemplateEntity, EMPTY_ARPITA_TEMPLATE_ENTITY, IArpitaOpenTemplateList } from "domain/entity/ArpitaTemplateEntity/ArpitaTemplateEntity";
import { EMPTY_ENVIRONMENT_VARIABLE_ENTITY, EnvironmentVariableEntity } from "domain/entity/EnvironmentVariables";
import { EMPTY_IMENU_ENTITY, IMenuEntity } from "domain/entity/Navigation";
import { E_Notification_Type } from "presentation/view/components/workspace/Notification/Notification";
import { v4 } from "uuid";

export interface WorkspaceModel {
    environmentVariable: EnvironmentVariableEntity
    identifier: string;
    entrypoint?: string;
    path?: string;
    showMainView?: boolean;
    mfeTitle?: string;
    mfeSupTitle?: any[];
    mainNavTitle?: string[];
    showNavigation?: boolean;
    supportingModulesList?: any[];
    miniToolbarArr: any[];
    expanded?: boolean;
    isSupportingModules?: boolean;
    showNotification?: boolean;
    message?: string;
    background?: string;
    modulesData?: any;
    workspaceDropdown?: any;
    showVersion?: boolean;
    counter?: any;
    mainViewWidth?: any;
    dataFromLocal?: any;
    dragSubModArr?: any;
    mfeFullscreen?: boolean;
    mfeGraphic?: boolean;
    isMainMenu?: boolean;
    mfePageInformation?: any;
    defaultPageInformation?: any;
    showChargeSearch?: boolean;
    showDocumentSearch?: boolean;
    showDocumentItemSearch?: boolean;
    showArpitaTemplate?: boolean;
    showModalForVersionInformation?: boolean;
    showModalForVersionIcon?: boolean;
    mainMenu: IMenuEntity;
    arpitaTemplates: ArpitaTemplateEntity[];
    openArpitaTemplates?: IArpitaOpenTemplateList;
    arpitaFocusTemplate?: string,
    notification: INotificationState,
    navigationBarPinned: boolean,
    showDefaultEntryPoint: boolean,
}

export interface INotificationState {
    isShowNotification: boolean;
    notificationType: E_Notification_Type;
    notificationMessage: string;
    isActiveScreen: boolean;
}

export const EMPTY_NOTIFICATION_STATE: INotificationState = {
    isShowNotification: false,
    notificationType: E_Notification_Type.SUCCESS,
    notificationMessage: '',
    isActiveScreen: false,
}

export const EMPTY_WORKSPACE_MODEL: WorkspaceModel = {
    environmentVariable: { ...EMPTY_ENVIRONMENT_VARIABLE_ENTITY },
    identifier: v4(),
    entrypoint: "",
    path: "",
    showMainView: false,
    mfeTitle: "",
    mfeSupTitle: [],
    mainNavTitle: [],
    showNavigation: false,
    supportingModulesList: [],
    miniToolbarArr: [],
    expanded: true,
    isSupportingModules: false,
    showNotification: false,
    message: "",
    background: "",
    modulesData: [],
    workspaceDropdown: "",
    showVersion: false,
    counter: 0,
    mainViewWidth: "calc(100% - 62px)",
    dataFromLocal: {},
    dragSubModArr: [],
    mfeFullscreen: false,
    mfeGraphic: false,
    isMainMenu: false,
    mfePageInformation: null,
    defaultPageInformation: null,
    showChargeSearch: false,
    showDocumentSearch: false,
    showDocumentItemSearch: false,
    showArpitaTemplate: false,
    showModalForVersionInformation: false,
    showModalForVersionIcon: false,
    mainMenu: EMPTY_IMENU_ENTITY,
    arpitaTemplates: [EMPTY_ARPITA_TEMPLATE_ENTITY],
    openArpitaTemplates: undefined,
    notification: EMPTY_NOTIFICATION_STATE,
    navigationBarPinned: true,
    showDefaultEntryPoint: true,
};

export interface StoredWorkspaceModel {
    mfeTitle?: string;
    mfeSupTitle?: any[];
    mainNavTitle?: string[];
    isMainMenu?: boolean;
    showNavigation?: boolean;
    entrypoint?: string;
    path?: string;
    sectionId?: string;
    miniToolbarArr: any[];
    expanded?: boolean;
    isSupportingModules?: boolean;
    showMainView?: boolean;
    mfeFullscreen?: boolean;
    mfeGraphic?: boolean;
    defaultPageInformation?: any;
    mfePageInformation?: any;
}