export interface UserActivityLoginTrackingEntity {
    userEmail: string;
    identifier: string
    accessDate: Date,
    latitude: string,
    longitude: string,
    token: string,
    isLogin: boolean,
}

export const EMPTY_USER_ACTIVITY_LOGIN_TRACKING_ENTITY: UserActivityLoginTrackingEntity = {
    userEmail: '',
    identifier: '',
    accessDate: new Date(),
    latitude: '',
    longitude: '',
    token: '',
    isLogin: true
};

export const newUserActivityLoginTracking = (userEmail: string, identifier: string, latitude: string, longitude: string, token: string, isLogin: boolean): UserActivityLoginTrackingEntity => {
    return {
        ...EMPTY_USER_ACTIVITY_LOGIN_TRACKING_ENTITY,
        userEmail: userEmail,
        identifier: identifier,
        accessDate: new Date(),
        latitude: latitude,
        longitude: longitude,
        token: token,
        isLogin: isLogin
    };
};
