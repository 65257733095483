import { BuProfileEntity } from "domain/entity/BuProfile/BuProfileEntity";
import { axiosGetDataFromPublicAPI } from "domain/repository/axios/AxiosBasicImpl";
import { OAuthContext } from "hph-auth";
import Keycloak from 'keycloak-js';
import { NbisivPermission } from "presentation/constant/ANAInfo/NbisivPermission";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import useLoginAssist from "./LoginAssist";

interface Props {
    config: string | Keycloak.KeycloakConfig;
    children: JSX.Element;
}

// const redirectUriHome = "nbisiv-home";

const Login = (props: Props) => {
    const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
    const [keycloak] = useState(new Keycloak(props.config));
    const [keycloakRefreshCounter, setKeycloakRefreshCounter] = useState(1);
    const [operatingCompanies, setOperatingCompanies] = useState<string[]>([]);
    const [currentOperatingCompany, setCurrentOperatingCompany] = useState<string | null>(null);
    const [defaultEntryPoint, setDefaultEntryPoint] = useState<string | null>(null);
    const [permissions, setPermissions] = useState<{[key:string]:NbisivPermission} | null>(null);
    const [currentBu] = useState<string>("ECT");
    const [country, setCountry] = useState<string>("");
    const [timezone, setTimezone] = useState<string>("");
    const loginAssist = useLoginAssist();    
    // const public_uri = process.env.REACT_APP_BU_RPOFILE_URI;

    const loginCallback = useCallback(async() => {
        const tokenParsed = (keycloak as any).tokenParsed;
        if (!tokenParsed) return;
        const userName = tokenParsed.preferred_username;
        if (!userName) return;

        if (userName === "support") {
            await loginAssist.getBuProfile("ECT").then((buProfile:BuProfileEntity) => {
                if (buProfile) {
                    console.log("get bu successful");
                    const companies:string[] = loginAssist.getOperatingCompaniesForSupport(buProfile)??[];
                    setOperatingCompanies(companies);
                    setCountry(buProfile.country??"");
                    setTimezone(buProfile.timezone??"");
                    console.log("set compaines: " + companies);
                    
                    loginAssist.getUser("support").then(user => {
                        if (user) {
                            console.log("get user successful");
                            const operatingCompany = user.switchOperatingCompany && user.switchOperatingCompany !== user.defaultOperatingCompany ? user.switchOperatingCompany : user.defaultOperatingCompany
                            setCurrentOperatingCompany(operatingCompany??"");
                            setDefaultEntryPoint(user.defaultEntryPoint??""); 
                            
                            console.log("default entry point and operating company" + user.defaultOperatingCompany + " " + user.defaultEntryPoint );

                            loginAssist.getUserGroup(userName).then(data => {
                                if (data) {
                                    console.log("get user group successful");
                                    loginAssist.getPermissionsForSupport(data, companies).then(permissions => {
                                        console.log("get permission successful");
                                        setPermissions(permissions);
                                        // console.log("permissions" + permissions ); 
                                    })
                                }
                            })                             
                        }
                    });                  
                }
            });
        } else {
            await loginAssist.getUser(userName).then(user => {
                if (user) {
                    if (user) {
                        console.log("get user successful");
                        const operatingCompany = user.switchOperatingCompany && user.switchOperatingCompany !== user.defaultOperatingCompany ? user.switchOperatingCompany : user.defaultOperatingCompany
                        setCurrentOperatingCompany(operatingCompany??"");
                        setDefaultEntryPoint(user.defaultEntryPoint??"undefined");
                        console.log("default entry point and operating company" + user.defaultOperatingCompany + " " + user.defaultEntryPoint );  

                        loginAssist.getUserGroup(userName).then(data => {
                            console.log("get user group successful");
                            Promise.allSettled([
                                loginAssist.getBuProfile("ECT").then((buProfile:BuProfileEntity) => {
                                    if (buProfile) {
                                        setCountry(buProfile.country??"");
                                        setTimezone(buProfile.timezone??"");
                                    }
                                }),
                                loginAssist.getPermissionsForUser(data).then(permissions => {
                                    if (permissions) {
                                        console.log("get permission successful");
                                        setPermissions(permissions);
                                        // console.log("permissions" + permissions ); 
                                        const compaines = loginAssist.getOperatingCompaniseForUser(permissions);
                                        setOperatingCompanies(compaines);
                                        if (!operatingCompany) {
                                            if (compaines && compaines.length > 0) {
                                                setCurrentOperatingCompany(compaines[0]);
                                            } else {
                                                setCurrentOperatingCompany("2691");
                                            }
                                        }
                                        console.log("set compaines: " + compaines);
                                    }
                                })
                            ]);                            
                        })
                    }
                }
            });                            
        }
        await loginAssist.updateLatestLoginDatetime(userName);
    }, [keycloak, loginAssist]);

    const encryptDefaultBuToken = useCallback(async(userName:string, operatingUnit:string) => {
        axiosGetDataFromPublicAPI(`/encryptDefaultBuToken/${userName}/${operatingUnit}`).then((res) => {
            if (res) {
                console.log("default bu token: " + res.data);
                localStorage.setItem("access_bu_token", res.data as string);
                loginCallback();                
            }
        })
    }, [loginCallback]);

    useEffect(() => {
        const initKeycloak = async () => {
            await keycloak.init({
                onLoad: 'login-required',
                checkLoginIframe: false,
                pkceMethod: 'S256',
            }).then((authenticated) => {
                if (authenticated) {
                    localStorage.setItem("access_token_with_refresh", keycloak.token??"");
                    if (keycloak?.tokenParsed?.bu_url && !(keycloak?.tokenParsed?.bu_url.includes(window.location.hostname)) && process.env.NODE_ENV !== 'development') {
                        window.location = keycloak.tokenParsed.bu_url
                    }                    
                    setAuthenticated(true);
                    setKeycloakRefreshCounter(prevState => prevState + 1);
                    
                    encryptDefaultBuToken((keycloak.tokenParsed as any).preferred_username, currentBu);                    
                } else {
                    keycloak.login();
                }
            }).catch(() => {
                console.error('Authentication Failed');                
            });
        }

        if (!isAuthenticated) {
            localStorage.removeItem("access_bu_token");
            initKeycloak();
        }
    }, [isAuthenticated, keycloak, loginCallback, currentBu, encryptDefaultBuToken]); //public_uri

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (!keycloak) return;
        
        const refreshInterval = setInterval(() => {
            if (keycloak.tokenParsed && Date.now() > (keycloak.tokenParsed.exp ?? 0) * 1000 - 20000) {
                keycloak.updateToken(60).then((refreshed) => {
                    if (refreshed) {
                        setKeycloakRefreshCounter(prevState => prevState + 1);
                    } else {
                        console.error('Failed to refresh the token, or the session has expired');
                        keycloak.logout();
                        setAuthenticated(false);
                    }
                }).catch(() => {
                    console.error('Failed to refresh the token, or the session has expired');
                    keycloak.logout();
                    setAuthenticated(false);
                });
            }
        }, 10000); // Check every 10 seconds

        keycloak.onTokenExpired = () => {
            keycloak.updateToken(60).then((result) => {
                if (result) {
                    setKeycloakRefreshCounter(prevState => prevState + 1)
                } else {
                    console.error('Failed to refresh the token, or the session has expired');
                    keycloak.logout()
                    setAuthenticated(false)
                }
            })
                .catch(() => {
                    console.error('Failed to refresh the token, or the session has expired');
                    keycloak.logout()
                    setAuthenticated(false)
                });
        }

        return () => {
            clearInterval(refreshInterval); // Clean up interval on component unmount
        };
    }, [keycloak]);

    const memoKeycloak = useMemo(() => {
        return {
            ...keycloak as Keycloak.KeycloakInstance,
            token: keycloak!!.token,
            tokenParsed: keycloak!!.tokenParsed,
            currentOperatingCompany,
            defaultEntryPoint,
            operatingCompanies,
            permissions,
            bu: currentBu,
            country: country,
            timezone: timezone,
            buToken: {
                operatingCompany: currentOperatingCompany,
                operatingUnit: currentBu,                
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloakRefreshCounter, currentOperatingCompany, defaultEntryPoint, operatingCompanies, 
        permissions, currentBu, country, timezone])

    return (
        // isAuthenticated
        //     ? permissions !== null && operatingCompanies.length > 0 && defaultEntryPoint !== null && currentOperatingCompany !== null
        //     ? <OAuthContext.Provider value={memoKeycloak}>{props.children}</OAuthContext.Provider>
        //     : <Loader Indicator="Stripe" size="Large" />
        //     : <React.Fragment></React.Fragment>
        <>
        {isAuthenticated &&
            <Suspense fallback={<Loader Indicator="Stripe" size="Large" />}>
                <>
                {(permissions && operatingCompanies && defaultEntryPoint && currentOperatingCompany) &&
                <OAuthContext.Provider value={memoKeycloak}>{props.children}</OAuthContext.Provider>
                }
                </>
            </Suspense>
        } 
        </>
    )
};

export { Login };

