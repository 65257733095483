import {
    ArpitaTemplateEntity,
    EMPTY_ARPITA_TEMPLATE_ENTITY,
    E_ArpitaHandlerAction,
    makeArpitaModalOptions,
    makeArpitaTemplate
} from "domain/entity/ArpitaTemplateEntity/ArpitaTemplateEntity";
import { ArpitaRepo } from "domain/repository/Arpita/ArpitaRepo";
import { NavigationRepositoryImpl } from "domain/repository/Navigation";
import { DialogUtils } from "helpers/DialogUtils";
import { E_ArpitaOperation, E_Custom_Dispatch_Event } from "helpers/Events";
import { WorkspaceModel } from "presentation/model/workspaceModel";
import { isEnvironmentForTesting } from "presentation/utils/EnvironmentVariable/EnvironmentVariableUtil";
import { textToSpeech } from "presentation/utils/TextToSpeech/TextToSpeech";
import { convertTemplateId } from "presentation/view/components/workspace/Modal/Modal";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";

interface NavigationMenuVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<WorkspaceModel>> | ((value: SetStateAction<WorkspaceModel>) => void),
    ],
    arpitaRepo: ArpitaRepo
}

const NavigationMenuVM = ({ dispatch, arpitaRepo }: NavigationMenuVMProps) => {
    const [workspaceDispatch] = dispatch

    //TODO: should not use any... but change later if have time.
    const findPath = (ob: any, key: any, value: any) => {
        const path: any = [];
        const keyExists: any = (obj: any) => {
            if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
                return false;
            } else if (obj.hasOwnProperty(key) && obj[key] === value) {
                return true;
            } else if (Array.isArray(obj)) {
                path.pop();
                for (let i = 0; i < obj.length; i++) {
                    const result = keyExists(obj[i], key);
                    if (result) {
                        path.push(obj[i][key]);
                        return result;
                    }
                    path.pop();
                }
            } else {
                for (const k in obj) {
                    path.push(k);
                    const result = keyExists(obj[k], key);
                    if (result) {
                        return result;
                    }
                    path.pop();
                }
            }
            return false;
        };
        keyExists(ob);
        let myPath: any = [];
        myPath = path?.reverse()
        return myPath
    }

    const onOpenMFE = (e: any, userName: string) => {
        workspaceDispatch(prevState => {
            const { navigation } = new NavigationRepositoryImpl().getNavigation(prevState.mainMenu);
            let path = findPath(navigation, "title", e.title);

            if (isEnvironmentForTesting(prevState.environmentVariable)) {
                textToSpeech(`${e.title} is opened`)
            }
            let breadcrumbArray = new CustomEvent('breadcrumbArray', { detail: path })
            document.dispatchEvent(breadcrumbArray);

            return {
                ...prevState,
                supportingModulesList: e.supportingModulesList,
                showVersion: false,
                mfeTitle: e.customTitle ? e.customTitle : e.title,
                mfeSupTitle: (path.slice(0, -1)),
                mainNavTitle: e.mainNavTitle,
                sectionId: '',
                isMainMenu: e.isMainMenu,
                showNavigation: true,
                workspaceDropdown: "",
                entrypoint: e.entrypoint,
                path: e.path,
                miniToolbarArr: [],
                expanded: true,
                isSupportingModules: e.supportingModulesList,
                // showMainView: e.entrypoint && e.path ? true : false,
                showMainView: e.entrypoint ? true : false,
                mfeFullscreen: true,
                mfeGraphic: e.graphical,
                defaultPageInformation: e.defaultPageInformation,
                ...e.isMainMenu && { mfePageInformation: null },
                showDefaultEntryPoint: false
            }
        });
    };
    const arpitaDailogHandler = (token: string | undefined, e: any, type: E_ArpitaHandlerAction, templateId: string, template?: ArpitaTemplateEntity, other?: any, cb?: Function) => {
        const openTId: string = (type === E_ArpitaHandlerAction.OPEN_FROM_MORE || type === E_ArpitaHandlerAction.NEW || type === E_ArpitaHandlerAction.CLOSE) ? templateId : (template?.id || '');
        const tId: string = convertTemplateId(template ? template.id : templateId);
        const elementId: string = `a-${tId.toString().toLowerCase()}-a`;

        switch (type) {
            case E_ArpitaHandlerAction.OPEN:
                workspaceDispatch((prevState: WorkspaceModel) => {
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates };

                    cb && cb();
                    if (openArpitaTemplates[tId]) return { ...prevState, showArpitaTemplate: true, arpitaFocusTemplate: openTId };

                    openArpitaTemplates[tId] = { openTId, elementId, templateId, template, position: Object.keys(openArpitaTemplates).length + 1, modalOptions: makeArpitaModalOptions(true, false, true) };
                    return { ...prevState, showArpitaTemplate: true, openArpitaTemplates };
                });
                break
            case E_ArpitaHandlerAction.CREATE_DATATABLE:
            case E_ArpitaHandlerAction.CREATE_TEMPLATE_FROM_SELECTION:
                workspaceDispatch(prevState => {
                    // Its open only one new template
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates };

                    if (openArpitaTemplates[tId]) return { ...prevState, showArpitaTemplate: true, arpitaFocusTemplate: openTId };

                    openArpitaTemplates[tId] = { openTId, elementId, templateId: tId, position: Object.keys(openArpitaTemplates).length + 1, modalOptions: makeArpitaModalOptions(templateId === E_ArpitaOperation.NEW, templateId === E_ArpitaOperation.NEW, true), jsonData: JSON.stringify(other) }
                    return { ...prevState, showArpitaTemplate: true, openArpitaTemplates };
                });
                break;
            case E_ArpitaHandlerAction.OPEN_FROM_MORE:
                workspaceDispatch(prevState => {
                    const temp: ArpitaTemplateEntity | undefined = prevState.arpitaTemplates.find((t: ArpitaTemplateEntity) => t.id === tId);
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates };
                    if (!openArpitaTemplates[tId]) openArpitaTemplates[tId] = { openTId, elementId, templateId, template: temp, position: Object.keys(openArpitaTemplates).length + 1, modalOptions: makeArpitaModalOptions(true, true, true) };

                    return { ...prevState, showArpitaTemplate: true, arpitaFocusTemplate: openTId, openArpitaTemplates };
                });
                break
            case E_ArpitaHandlerAction.CLOSE:
                workspaceDispatch((prevState: any) => {
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates };
                    if (openArpitaTemplates[tId]) delete openArpitaTemplates[tId];

                    return { ...prevState, openArpitaTemplates: { ...openArpitaTemplates }, arpitaFocusTemplate: openTId, showArpitaTemplate: Object.keys(openArpitaTemplates).length > 0 ? true : false };
                });
                break;
            case E_ArpitaHandlerAction.RESTORE_FROM_MINIMISE:
                workspaceDispatch((prevState: any) => {
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates, [tId]: { ...prevState.openArpitaTemplates[tId], modalOptions: { ...prevState.openArpitaTemplates[tId].modalOptions, showModalAgian: false } } };

                    return { ...prevState, openArpitaTemplates: { ...openArpitaTemplates } };
                });
                break;
            case E_ArpitaHandlerAction.NEW:
                workspaceDispatch(prevState => {
                    // Its open only one new template
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates };

                    if (openArpitaTemplates[tId]) return { ...prevState, showArpitaTemplate: true, arpitaFocusTemplate: openTId };

                    openArpitaTemplates[tId] = { openTId, elementId, templateId: tId, position: Object.keys(openArpitaTemplates).length + 1, modalOptions: makeArpitaModalOptions(templateId === E_ArpitaOperation.NEW, templateId === E_ArpitaOperation.NEW, true) }
                    return { ...prevState, showArpitaTemplate: true, openArpitaTemplates };
                });
                break;
            case E_ArpitaHandlerAction.FULLSCREEN:
                workspaceDispatch(prevState => {
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates };

                    if (!openArpitaTemplates[tId]) return { ...prevState, showArpitaTemplate: true };

                    openArpitaTemplates[tId] = { ...openArpitaTemplates[tId], modalOptions: makeArpitaModalOptions(openArpitaTemplates[tId]?.modalOptions, !openArpitaTemplates[tId]?.modalOptions?.isFullScreen) };
                    return { ...prevState, showArpitaTemplate: true, arpitaFocusTemplate: openTId, openArpitaTemplates };
                });
                break;
            case E_ArpitaHandlerAction.FOCUS:
                workspaceDispatch((prevState: any) => {
                    const openArpitaTemplates = { ...prevState.openArpitaTemplates, [tId]: { ...prevState.openArpitaTemplates[tId], modalOptions: { ...prevState.openArpitaTemplates[tId].modalOptions, showModalAgian: true } } };

                    return { ...prevState, showArpitaTemplate: true, arpitaFocusTemplate: openTId, openArpitaTemplates: { ...openArpitaTemplates } };
                });
                break;
            case E_ArpitaHandlerAction.PIN:
                arpitaRepo.pinTemplate(template?.id || templateId, true).then((res) => {
                    if (res.toString().toLowerCase() === 'updated') {
                        workspaceDispatch(prevState => {
                            const templates: ArpitaTemplateEntity[] = prevState.arpitaTemplates.map((template: ArpitaTemplateEntity) => {
                                return {
                                    ...template,
                                    pinned: template.name.toLowerCase().trim() === templateId.toLowerCase().trim() ? true : template.pinned,
                                }
                            });

                            return { ...prevState, showArpitaTemplate: true, arpitaTemplates: [...templates,] };
                        });
                    }
                });
                break;
            case E_ArpitaHandlerAction.UNPIN:
                arpitaRepo.pinTemplate(template?.id || templateId, false).then((res) => {
                    if (res.toString().toLowerCase() === 'updated') {
                        workspaceDispatch(prevState => {
                            const templates: ArpitaTemplateEntity[] = prevState.arpitaTemplates.map((template: ArpitaTemplateEntity) => {
                                return {
                                    ...template,
                                    pinned: template.name.toLowerCase().trim() === templateId.toLowerCase().trim() ? false : template.pinned,
                                }
                            });

                            return { ...prevState, showArpitaTemplate: true, arpitaTemplates: [...templates,] };
                        });
                    }
                });
                break;
            case E_ArpitaHandlerAction.CLEAR:
                workspaceDispatch(prevState => {
                    return { ...prevState, showArpitaTemplate: false, arpitaTemplates: [], openArpitaTemplates: undefined, arpitaFocusTemplate: '' };
                });
                break;
            case E_ArpitaHandlerAction.CREATE_NEW_TEMPLATE:
                workspaceDispatch(prevState => {
                    const oTemplate: ArpitaTemplateEntity = makeArpitaTemplate(other.oTemplate);
                    const nTemplate: ArpitaTemplateEntity = makeArpitaTemplate(other.nTemplate);

                    const openArpitaTemplates = { ...prevState.openArpitaTemplates };

                    const checkTemplateId: string = oTemplate.id === '' ? E_ArpitaOperation.NEW : convertTemplateId(oTemplate.id);
                    const temp: ArpitaTemplateEntity = { ...EMPTY_ARPITA_TEMPLATE_ENTITY, ...nTemplate, isOpen: true };

                    const ele: any | undefined = DialogUtils.getElementStyles(`a-${convertTemplateId(checkTemplateId).toString().toLowerCase()}-a`);

                    // Create new template
                    openArpitaTemplates[convertTemplateId(nTemplate.id)] = { ...openArpitaTemplates[checkTemplateId], openTId: temp.id, elementId: `a-${convertTemplateId(temp.id).toString().toLowerCase()}-a`, templateId: temp.id, template: { ...temp }, position: Object.keys(openArpitaTemplates).length + 1, modalOptions: { ...openArpitaTemplates[checkTemplateId].modalOptions, showFullScreen: true, isResisable: true, isFullScreen: true, isMoveable: true, leftPosition: ele?.left, topPosition: ele?.top } };

                    // Delete older template from object
                    delete openArpitaTemplates[checkTemplateId];

                    cb && cb();

                    return { ...prevState, showArpitaTemplate: true, openArpitaTemplates, arpitaFocusTemplate: '' };
                });
                break;
        }
    }

    const chargeDataDailogHandler = (e: any) => {
        switch (e.detail.action) {
            case E_Custom_Dispatch_Event.CHARGE_DATA_EDIT_CRITERIA:
                // workspaceDispatch((prevState: any) => {
                //     return {
                //         ...prevState,
                //     }
                // });
                // let breadcrumbArray = new CustomEvent('breadcrumbArray', {detail: path});
                // document.dispatchEvent(breadcrumbArray);
                break;
        }
    }

    return {
        onOpenMFE: onOpenMFE,
        arpitaDailogHandler,
        chargeDataDailogHandler: chargeDataDailogHandler,
    };
};

export default NavigationMenuVM;