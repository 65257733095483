import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import { createRef, useCallback, useRef, useState } from "react";
import { Core } from "veronica-ui-component";

const createHeaders = (data: any) => {
    return data?.map((item: any) => ({
        text: item.title,
        id: item.id,
        drop: item.drop,
        disable: item.disabled,
        expanded: true,//item.expanded || false,
        container: item.container,
        ref: createRef(),
    }));
};

const {Container, IconButton,} = Core;

const SupportingModule = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [workspaceState, setWorkspaceState] = useWorkspaceTracked()
    const {miniToolbarArr, isSupportingModules, dragSubModArr, modulesData} = workspaceState;
    const moduleWrapperRowRef = useRef<HTMLDivElement>(null);
    const moduleWrapperRef = useRef<HTMLDivElement>(null);

    const mouseDown = (e: any, i: any) => {
        e.preventDefault();
        e.target.classList.remove("drag-over");
        e.target.classList.remove("drag-enter");
        setActiveIndex(i);
    };

    const mouseUp = useCallback(() => setActiveIndex(null), [setActiveIndex]);

    const mouseMover = useCallback(
        (val1: any, val2: any, resizeableHeight: any) => {
            let height: any;
            val2?.forEach((col: any, i: any) => {
                const currentDiv = col.ref.current;
                if (i === activeIndex && currentDiv) {
                    const filterVal2 = val2.filter(
                        (item: any) => item.id === col.id && item.drop === false
                    );
                    filterVal2.forEach(() => {
                        const currentOnDiv = col.ref.current;
                        const lastChild = col.ref.current.parentNode.lastChild;
                        const curHeight = val1.clientY - col.ref.current.offsetTop;
                        height = col.ref.current.clientHeight;
                        if (currentOnDiv) {
                            if (currentOnDiv.id === lastChild.id) {
                                currentOnDiv.style.height = `${curHeight + 20}px`;
                            } else currentOnDiv.style.height = `${curHeight}px`;
                        }
                    });
                    const filtersDrop = val2.filter((item: any) => item.drop === false);
                    const filters = val2.filter(
                        (item: any) => item.id !== col.id && item.drop === false
                    );
                    filters.forEach((item: any) => {
                        const currentoffDiv = item.ref.current;
                        const updated =
                            (resizeableHeight - height) / (filtersDrop.length - 1);
                        if (currentoffDiv) {
                            currentoffDiv.style.height = `${updated}px`;
                        }
                    });
                }
            });
        },
        [activeIndex]
    );

    miniToolbarArr.forEach((item: any) => {
        if (!item.disable) {
            const rlhw = document.getElementById(item.id)?.getBoundingClientRect();
            const modData = {
                id: item.id,
                title: item.title,
                supModExpanded: item?.expanded,
                disabled: item.disabled,
                container: item.container,
                top: rlhw?.top,
                left: rlhw?.left,
                width: rlhw?.width,
                height: rlhw?.height,
                drop: item.drop
            };
            const dropDuplicate: any = modulesData.findIndex(
                (id: any) => id.id === item.id
            );
            dropDuplicate >= 0
                ? (modulesData[dropDuplicate] = modData)
                : setWorkspaceState(prevState => ({
                    ...prevState,
                    modulesData: [...modulesData, modData]
                }))
        }
    });

    const closeBox = (id: any) => {
        miniToolbarArr.forEach((item: any) => {
            if (item.id === id) {
                item.disabled = false;
                item.expanded = false;
            }
        });
        const remainingModules = miniToolbarArr.filter((item: any) => item.id !== id);
        const removeData = modulesData.filter((item: any) => item.id !== id);
        setWorkspaceState(prevState => ({
            ...prevState,
            miniToolbarArr: remainingModules,
            modulesData: removeData
        }))
    };

    const expandBox = (id: any) => {
        const n = miniToolbarArr.map((item: any) => {
            if (item.id === id) {
                item.expanded = !item.expanded;
            }
            return item;
        });
        setWorkspaceState(prevState => ({
            ...prevState,
            miniToolbarArr: n
        }))
    };

    const sliceIntoChunks = (arr: any[], chunkSize: number) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }

        return res;
    };


    const dragstart_handler = (e: any) => {
        e.dataTransfer.setData("dropElemRightBar", e.target.id);
    };


    const dragOver = (e: any) => {
        e.preventDefault();
        e.target.classList.add("drag-over");
    };

    const drop = (e: any) => {
        e.preventDefault();
        e.target.classList.remove("drag-over");
        const id = e.dataTransfer.getData("dropElemMain");
        const dropToRight = e.dataTransfer.getData("dropElemRightBar");
        if (dropToRight) {
            miniToolbarArr.forEach((item: any) => {
                if (item.id === Number(dropToRight)) {
                    item.left = e.clientX - 55;
                    item.top = e.clientY;
                }
            });
        }
        const draggable = document.getElementById(id);
        if (draggable) {
            miniToolbarArr.forEach((item: any) => {
                if (item.id !== Number(id)) {
                    return item;
                }
            });
            setWorkspaceState(prevState => ({
                ...prevState,
                miniToolbarArr: miniToolbarArr
            }))

            const newMiniTool = miniToolbarArr.map((item: any) => {
                if (item.id === Number(id)) {
                    item.drop = false;
                }
                return item;
            });
            setWorkspaceState(prevState => ({
                ...prevState,
                miniToolbarArr: newMiniTool
            }))
        }

        let rightDrop = dragSubModArr.map((item: any) => {
            if (!item.drop) {
                dragSubModArr.splice(item, 1);
            }
            return item;
        });
        rightDrop = rightDrop.filter((n: any) => n);
        setWorkspaceState(prevState => ({
            ...prevState,
            dragSubModArr: rightDrop
        }))
    };

    const miniToolbarArrs = miniToolbarArr.filter((item: any) => item.drop === false)
    const rzHt: number | any = moduleWrapperRowRef.current?.getBoundingClientRect().height;
    const maxModules: number = Math.ceil(rzHt / 30);
    const numOfContainers: number = Math.ceil(miniToolbarArrs.length / maxModules);
    const newArr = numOfContainers <= 1 ? [miniToolbarArrs] : sliceIntoChunks(miniToolbarArrs, maxModules);

    const nMiniToolbar = newArr.map((item, i) => {
        item = item.map((subItem: any) => {
            subItem.container = `container_${i + 1}`;
            return subItem;
        });

        return item;
    });

    const minWidth =
        nMiniToolbar.length <= 1
            ? `${400}px`
            : `${300 + nMiniToolbar.length * 100}px`;

    return (
        <>
            {isSupportingModules && (
                <div
                    className={`side-bar ${isSupportingModules ? "" : "sidebar-hidden"}`}
                    onMouseUp={mouseUp}
                    style={{minWidth: minWidth}}
                >
                    <div
                        className="module-wrapper-row"
                        onDragOver={dragOver}
                        onDrop={drop}
                        ref={moduleWrapperRowRef}
                        id="sideBar"
                    >
                        {nMiniToolbar.length
                            ? nMiniToolbar.map((item: any, ind: number) => {
                                const currentContainer = item;

                                const newAr = item.filter((item: any) => item.drop === false);
                                const resizeableHeight: any = currentContainer
                                    ? moduleWrapperRowRef.current?.clientHeight
                                    : moduleWrapperRef.current?.clientHeight;

                                const currentSupportingModules =
                                    createHeaders(currentContainer);

                                const supModMaxHeight =
                                    resizeableHeight - 30 * (newAr.length - 1);

                                let supModHeight = resizeableHeight / newAr.length;

                                return (
                                    <div
                                        key={ind}
                                        className="module-wrapper mr-10"
                                        ref={moduleWrapperRef}
                                        onMouseMove={(e: any) =>
                                            mouseMover(
                                                e,
                                                currentSupportingModules,
                                                resizeableHeight
                                            )
                                        }
                                    >
                                        {currentSupportingModules?.map(
                                            ({id, ref, text, expanded, drop}: any, i: any) => (
                                                <div
                                                    className={`${
                                                        expanded
                                                            ? "sub-module-item sub-module--expended"
                                                            : "sub-module-item"
                                                    }`}
                                                    style={{
                                                        height: `${!drop && supModHeight}px`,
                                                        maxHeight: `${!drop && supModMaxHeight}px`,
                                                    }}
                                                    draggable="true"
                                                    onDragStart={dragstart_handler}
                                                    id={id}
                                                    ref={ref}
                                                    key={i}
                                                >
                                                    <Container
                                                        borderRadius="roundAll"
                                                        height="100%"
                                                        theme="theme3"
                                                        width="100%"
                                                    >
                                                        <div className="titleBar">
                                                            <div className="title">{text}</div>
                                                            <div className="actionButtons">
                                                                <IconButton
                                                                    onClick={() => expandBox(id)}
                                                                    fileName="Icon-adjust"
                                                                    size="medium"
                                                                    toolTipArrow={false}
                                                                    toolTipPlacement="bottom"
                                                                    toolTipText={"Expand"}
                                                                />
                                                                <IconButton
                                                                    onClick={() => closeBox(id)}
                                                                    fileName="Icon-cross"
                                                                    size="medium"
                                                                    toolTipArrow={false}
                                                                    toolTipPlacement="left"
                                                                    toolTipText={"Close"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Container>
                                                    <div
                                                        draggable="false"
                                                        className="resize-handler"
                                                        onMouseDown={(e: any) => mouseDown(e, i)}
                                                    ></div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default SupportingModule;
