import { ArpitaOpenTemplateEntity, ArpitaTemplateEntity, E_ArpitaHandlerAction } from "domain/entity/ArpitaTemplateEntity/ArpitaTemplateEntity";
import { ModalNavigationEntity } from "domain/entity/Navigation";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { MicroFrontend } from "presentation/view/components/workspace/MicroFrontend/MicroFrontend";
import { ArpitaModalHeader, E_Template_Type } from "./ArpitaModalHeader";
import { E_ArpitaOperation } from "helpers/Events";
import { SingleModal } from "./SingleModal";
import { DialogUtils } from "helpers/DialogUtils";
import { DomUtils } from "helpers/DomUtils";
import { useCallback } from "react";

export function convertTemplateId(templateId: string) {
  return templateId.toString().toLowerCase().trim().replaceAll("'", '').replaceAll('&', '').replaceAll(' ', '-').replaceAll('(', '-').replaceAll(')', '-').replaceAll('+', '-');
}

function getClassName(className: string, eleClassName?: string, templateId?: string) {
  if (templateId) return `${className} ${convertTemplateId(templateId)}`;
  if (eleClassName) return `${className}-${eleClassName}`;
  return className;
}

export interface IModal {
  isVisible?: boolean;
  showExistingHeader?: boolean;
  modalTitle: string | JSX.Element;
  dailogClass: string;
  maskClass?: string;
  openArpitaTemplate?: ArpitaOpenTemplateEntity;
  modalHeight?: string; // Height should be in pixel or percent
  enteryPointData: ModalNavigationEntity | undefined;
  resizable?: boolean;
  draggable?: boolean;
  modalContent?: JSX.Element;
  showMaximiseButton?: boolean;
  showMinimiseButton?: boolean;
  focusModalId?: string;
  onClose?: () => void;
  onArpitaClose?: (a: any, b: any, c: any, d: any, e?: any) => void;
  onMinimise?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMinimiseCallbackOnly?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export interface IFullScreen {
  templateId: string | undefined;
  isFullScreen: boolean;
}

export const Modal: React.FC<IModal> = ({ isVisible = false, showExistingHeader = true, modalTitle, dailogClass, openArpitaTemplate, modalHeight, enteryPointData, resizable = true, draggable = true, modalContent = undefined, showMaximiseButton = false, showMinimiseButton = false, maskClass = '', focusModalId, onClose, onArpitaClose, onMinimiseCallbackOnly }: IModal) => {
  const { token } = useANAInfo();

  const onArpitaFullScreen = useCallback((e: any, isFullScreen: boolean, tId: string, template: ArpitaTemplateEntity | undefined) => {
    if (isFullScreen && onArpitaClose) {
      onArpitaClose(token, e, E_ArpitaHandlerAction.FULLSCREEN, tId, template);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onArpitaCloseModal = useCallback((e: any, tId: string) => {
    if (onArpitaClose) {
      onArpitaClose(token, e, E_ArpitaHandlerAction.CLOSE, tId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onArpitaMiniMaxi = useCallback((status: boolean, tId: string, template: ArpitaTemplateEntity | undefined) => {
    onArpitaClose && onArpitaClose(token, {}, E_ArpitaHandlerAction.RESTORE_FROM_MINIMISE, tId, template);
  }, [onArpitaClose, token]);

  const getMfe = useCallback((path?: string, entrypoint?: string, sectionId?: string, jsonData?: string): JSX.Element => {
    if (entrypoint) { //path && 
      // return
      // <Suspense fallback={<Loader Indicator="Stripe" size="Large" />}>
      return  <MicroFrontend path={path??""} entrypoint={entrypoint} sectionId={sectionId} jsonData={jsonData} isModelOpen={true} />;
      // </Suspense>
      ;
    }
      // if (path) {      
    //     return <MicroFrontend path={path} entrypoint={entrypoint} sectionId={sectionId} jsonData={jsonData} isModelOpen={true} />;    
    // }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModal = ({ openTId, elementId, template, templateId, modalOptions, position, jsonData }: ArpitaOpenTemplateEntity): JSX.Element => {
    const tId: string = convertTemplateId(template ? template.id : templateId);
    const templateType: E_Template_Type = templateId === E_ArpitaOperation.MORE ? E_Template_Type.TEMPLATE_LIST : (template?.isMaster ? E_Template_Type.MASTER : (template?.templateType?.toString().toLowerCase() === 'data table' ? E_Template_Type.DATATABLE : E_Template_Type.TEMPLATE));
    return <SingleModal
      modalId={DomUtils.mfeContainer('arpitaTemplate', openTId)}
      key={elementId}
      modalClass={getClassName(`${templateId === E_ArpitaOperation.MORE ? 'more-list' : ''} ${dailogClass}`, '', DomUtils.mfeContainer('arpitaTemplate', openTId))}
      visible={isVisible}
      headerHtml={<ArpitaModalHeader templateType={templateType} templateName={template?.name || 'Untitled'} />}
      showMaximiseButton={showMaximiseButton && templateId !== E_ArpitaOperation.MORE && (modalOptions && modalOptions.showFullScreen)}
      showModalAgain={modalOptions?.showModalAgian}
      contentHtml={getMfe(enteryPointData?.path, enteryPointData?.entrypoint, enteryPointData?.sectionId, jsonData)}
      draggable={draggable}
      resizable={modalOptions && modalOptions.showFullScreen && templateId !== E_ArpitaOperation.MORE}
      width={templateId === E_ArpitaOperation.MORE ? '550px' : '1100px'}
      maskClassName={`p-dailog-focus p-dailog-active p-arpita-modal${maskClass !== '' ? ' ' + maskClass : ''} ${templateId === E_ArpitaOperation.MORE ? 'p-arpita-more-list' : ''}`}
      style={{ ...DialogUtils.getDailogStyles(position, modalOptions), ...(modalHeight ? { height: templateId === E_ArpitaOperation.MORE ? '600px' : modalHeight } : { height: '100%' }) }}
      onClose={(e: React.MouseEvent<HTMLAnchorElement>) => onArpitaCloseModal(e, tId)}
      onModalShowAgain={(status) => onArpitaMiniMaxi(status, tId, template)}
      maskStyle={{ zIndex: `${DialogUtils.getStyleForMask((modalOptions && modalOptions.isFullScreen) ? 500 : 100)} !important` }}
      baseZIndex={DialogUtils.getStyleForMask((modalOptions && modalOptions.isFullScreen) ? 500 : 100)}
      isMinimiseOnDrag={true}
      focusModalId={focusModalId}
      onFullScreen={(e: React.MouseEvent<HTMLAnchorElement>, isFullScreen: boolean) => onArpitaFullScreen(e, isFullScreen, tId, template)} />;
  };

  if (openArpitaTemplate) return getModal(openArpitaTemplate);

  return <SingleModal
    modalId={DomUtils.mfeContainer(enteryPointData?.entrypoint, enteryPointData?.sectionId)}
    visible={isVisible}
    headerHtml={showExistingHeader ? (typeof modalTitle !== 'string' ? modalTitle : <>{modalTitle}</>) : null}
    contentHtml={modalContent ? modalContent : getMfe(enteryPointData?.path, enteryPointData?.entrypoint, enteryPointData?.sectionId)}
    draggable={draggable}
    resizable={resizable}
    modalClass={`${dailogClass} ${DomUtils.mfeContainer(enteryPointData?.entrypoint, enteryPointData?.sectionId)}`}
    maskClassName={`p-dailog-focus p-dailog-active${maskClass !== '' ? ' ' + maskClass : ''}`}
    showMaximiseButton={showMaximiseButton}
    showMinimiseButton={showMinimiseButton}
    width={''}
    style={{ ...(modalHeight ? { height: modalHeight } : { height: '100%' }) }}
    onClose={() => onClose && onClose()}
    onMinimiseCallbackOnly={onMinimiseCallbackOnly}
    maskStyle={{ zIndex: `${DialogUtils.getStyleForMask()}` }}
    baseZIndex={DialogUtils.getStyleForMask()} />
}