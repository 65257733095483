import { useANAInfoRequestReceiver } from "presentation/hook/ANAInfo/useANAInfoRequestReceiver";
import Layout from "presentation/layout/Layout";
import { Suspense } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import usePreventZoom from 'usePreventZoom';
import { GlobalStyle, Loader } from "veronica-ui-component/dist/component/core";

function App() {
    usePreventZoom();
    useANAInfoRequestReceiver();
    
    return (
        <Router>
            <GlobalStyle/>
            <Suspense fallback={<Loader Indicator="Stripe" size="Large" />}>
                <Layout /> 
            </Suspense>
        </Router>
    );
}

export default App;
