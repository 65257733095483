import axios from "axios";
import {EnvironmentVariableRepo} from "./EnvironmentVariableRepo";
import {EMPTY_ENVIRONMENT_VARIABLE_ENTITY, EnvironmentVariableEntity} from "domain/entity/EnvironmentVariables";

export const EnvironmentVariableRepoImpl = (): EnvironmentVariableRepo => {
    const getEnvironmentVariable = async (): Promise<EnvironmentVariableEntity> => {
        const result = axios.get('/environmentVariables.json')
            .then(res => {
                return res.data;
            })
            .catch(() => {
                return EMPTY_ENVIRONMENT_VARIABLE_ENTITY
            });
        return result;
    };

    return {
        getEnvironmentVariable: getEnvironmentVariable,
    };
};
