import { OAuthContext } from "hph-auth";
import { KJUR } from "jsrsasign";
import { AllNbisivPermission, BuToken } from "presentation/constant/ANAInfo/NbisivPermission";
import { useContext, useEffect, useState } from "react";


const ADDITIONAL_EXPIRE_TIME_ADDED_IN_SECONDS = 30
function setCookie(name: string, value: string, seconds: number) {
    let expires = "";
    const expiredInSecond = seconds + ADDITIONAL_EXPIRE_TIME_ADDED_IN_SECONDS
    if (expiredInSecond) {
        const date = new Date();
        date.setTime(date.getTime() + ((expiredInSecond) * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

//need to care how to handle the butoken
export const useANAInfo = () => {
    const anaKeycloak = useContext(OAuthContext);
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [token, setToken] = useState<string | undefined>("");
    const [buToken, setBuToken] = useState<BuToken | undefined>({});
    const [currentBu, setCurrentBu] = useState<string>("");
    const [availableBuList, setAvailableBuList] = useState<string[]>([]);
    const [allNbisivPermission, setAllNbisivPermission] = useState<AllNbisivPermission | undefined>();
    const [availabeOperatingCompanies, setAvailabeOperatingCompanies ] = useState<string[]>([]);
    const [operatingUnit, setOperatingUnit] = useState<string>("ECT");
    const [currentOperatingCompany, setCurrentOperatingCompany] = useState<string>("2691");
    const [entryPoint, setEntryPoint] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [timezone, setTimezone] = useState<string>("");

    useEffect(() => {
        if (anaKeycloak) {
            setToken(anaKeycloak.token);
            (window as any).keycloak_token = anaKeycloak.token;
            if (anaKeycloak.tokenParsed !== undefined) {
                localStorage.setItem('access_token_with_refresh', anaKeycloak.token ?? '')
                const expirationTime = anaKeycloak.tokenParsed.exp;
                const currentTime = Math.floor(Date.now() / 1000);
                const remainingTime = expirationTime ? (expirationTime - currentTime) : 0;
                const k = 'qcweinqwfelausandui';
                const key = k.substring(0, 63);
                const header = { alg: 'HS256', typ: 'JWT' };
                //no use now, wait for merge into ana
                // const payload = { otk: anaKeycloak.tokenParsed.otk, hph_permissions: {} };
                const payload = { otk: anaKeycloak.tokenParsed.otk, hph_permissions: (anaKeycloak as any).permissions };;
                const cookieResult = KJUR.jws.JWS.sign('HS256', header, payload, key);
                setCookie('X-HPH-GW-AUTH', cookieResult, remainingTime);

                setCurrentBu((anaKeycloak as any).bu);
                setOperatingUnit("ECT");
                setAvailableBuList((anaKeycloak.tokenParsed as any).bu_list);
                // setAllNbisivPermission((anaKeycloak.tokenParsed as any).hph_permissions);
                setAllNbisivPermission(payload.hph_permissions);
                setUserName((anaKeycloak.tokenParsed as any).preferred_username);
                setEmail((anaKeycloak.tokenParsed as any).email);
                setBuToken((anaKeycloak as any).buToken);
                setAvailabeOperatingCompanies((anaKeycloak as any).operatingCompanies);
                setCurrentOperatingCompany((anaKeycloak as any).currentOperatingCompany);
                setEntryPoint((anaKeycloak as any).defaultEntryPoint);
                setCountry((anaKeycloak as any).country);
                setTimezone((anaKeycloak as any).timezone);
            }                            
        }
    }, [allNbisivPermission, anaKeycloak, userName, availableBuList, operatingUnit]);

    return { 
        anaKeycloak, 
        email, 
        userName, 
        token, 
        buToken, 
        currentBu, 
        availableBuList, 
        allNbisivPermission, 
        operatingUnit, 
        currentOperatingCompany,
        availabeOperatingCompanies,
        entryPoint,
        country,
        timezone
    };
};
