import {FrontendConsoleType} from "presentation/constant/FrontendConsoleType";

export interface FrontendConsoleTrackingEntity {
    userEmail: string,
    identifier: string,
    logDate: Date,
    type: FrontendConsoleType,
    message: string,
    stacktrace: string,
}

export const EMPTY_FRONTEND_CONSOLE_TRACKING_ENTITY: FrontendConsoleTrackingEntity = {
    userEmail: '',
    identifier: '',
    logDate: new Date(),
    type: FrontendConsoleType.LOG,
    message: '',
    stacktrace: '',
};

export const newFrontendConsoleTracking = (userEmail: string, identifier: string, type: FrontendConsoleType, message: string, stacktrace: string): FrontendConsoleTrackingEntity => {
    return {
        ...EMPTY_FRONTEND_CONSOLE_TRACKING_ENTITY,
        userEmail: userEmail,
        identifier: identifier,
        logDate: new Date(),
        type: type,
        message: message,
        stacktrace: stacktrace,
    };
};
