import BaseViewModel from "presentation/viewModel/BaseViewModel";
import {
    UserActivityLoginTrackingRepo
} from "domain/repository/UserActivityLoginTracking/UserActivityLoginTrackingRepo";
import {UserActivityLoginTrackingEntity} from "domain/entity/UserTracking/UserActivityLoginTrackingEntity";

interface LoginTrackingVMProps extends BaseViewModel {
    dispatch: [];
    userActivityLoginTrackingRepo: UserActivityLoginTrackingRepo;
}

const LoginTrackingVM = ({dispatch, userActivityLoginTrackingRepo}: LoginTrackingVMProps) => {
    const uploadUserLoginTracking = (userLoginTracking: UserActivityLoginTrackingEntity) => {
        userActivityLoginTrackingRepo.createNewLoginTracking(userLoginTracking)
    };

    return {
        uploadUserLoginTracking: uploadUserLoginTracking,
    };
};

export default LoginTrackingVM;
