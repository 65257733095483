export const LocalDataService = {
  getData(label: any) {
    return localStorage.getItem(label);
  },

  postData(label: any, data: any) {
    return localStorage.setItem(label, JSON.stringify(data));
  },

  putData(label: any, data: any) {
    return localStorage.setItem(label, JSON.stringify(data));
  },

  deleteData(label: any) {
    return localStorage.removeItem(label);
  },
};
