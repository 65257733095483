import { UserActivityMFEAccessEntity } from "../../entity/UserTracking/UserActivityMFEAccessEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { UserActivityMFEAccessRepository } from "./UserActivityMFEAccessRepo";

export const UserActivityMFEAccessRepoImpl = (): UserActivityMFEAccessRepository => {
    const url = '/v1/useractivitymfeaccess'

    const createNewMFEAccess = async(newData: UserActivityMFEAccessEntity) => {
        await axiosPostData(authAxiosInstance, url, newData);
    };

    return {
        createNewMFEAccess: createNewMFEAccess,
    };
};
