import { NotificationBar } from "veronica-ui-component/dist/component/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import NotificationVM from "presentation/viewModel/workspace/NotificationVM/NotificationVM";
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";

const NOTIFICATION_INITIAL_TIME = 20;

export enum E_Notification_Type {
  SUCCESS = 'success',
  ALERT = 'alert',
  WARNING = 'warning',
}

export const Notification: React.FC = () => {
  const [time, setTime] = useState(NOTIFICATION_INITIAL_TIME);
  const [workspaceState, setWorkspaceState] = useWorkspaceTracked();
  const { notificationMessage, notificationType } = workspaceState.notification;
  const notificationVM = useMemo(() => NotificationVM({ dispatch: [setWorkspaceState], }), [setWorkspaceState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClearNotification = useCallback((interval?: any) => { notificationVM.clearNotification(); interval && clearInterval(interval); }, []);

  useEffect(() => setTime(NOTIFICATION_INITIAL_TIME), [notificationMessage, notificationType]);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (time > 0) {
        setTime(prevState => prevState - 1);
      } else {
        handleClearNotification(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);
  
  return <NotificationBar className={`notification-bar ${notificationType}`} width={'100%'} height={'50px'} time={time} onClick={handleClearNotification} text={notificationMessage} />;
}