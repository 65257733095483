import React from "react";
import { Core } from "veronica-ui-component";

interface OverflowingMenuProps {
  [x: string]: any;
}

const OverflowingMenu: React.FC<OverflowingMenuProps> = ({
  menuItem,
}): any => {

  const OverflowingMenu = Core.HPHOverflowingMenu;

  return (
    <div className="navigation-item">
        <OverflowingMenu
          showIcon={true}
          icon={menuItem?.icon}
          iconToolTipArrow={false}
          iconTooltipDisable={false}
          iconToolTipPlacement="right"
          iconToolTipText={menuItem?.title}
          iconEnabled={menuItem?.entrypoint}
          iconClassName="navigation-button"
          width="auto"
          height="auto"
          boxPosition="right"
          menuItem={menuItem?.navigationList}
        />
      </div>
  );
};
export { OverflowingMenu };
