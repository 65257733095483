import {useEffect, useMemo} from "react";
import {EnvironmentVariableRepoImpl} from "domain/repository/EnvironmentVariable/EnvironmentVariableRepoImpl";
import EnvironmentVariableVM from "presentation/viewModel/workspace/EnvironmentVariableVM/EnvironmentVariableVM";
import {useWorkspaceTracked} from "presentation/store/NavigationMenu/NavigationMenu";

export const useEnvironmentVariableConfig = () => {
    const [, setWorkspaceState] = useWorkspaceTracked()
    const environmentVariableVM = useMemo(() =>
        EnvironmentVariableVM({
            dispatch: [setWorkspaceState],
            environmentParameterRepo: EnvironmentVariableRepoImpl()
        }), [setWorkspaceState]
    )
    useEffect(() => environmentVariableVM.setupEnvironmentVariable(), [environmentVariableVM])
};
