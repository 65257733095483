import { commonNotification } from "domain/utils/commonNotification";
import { DomUtils } from "helpers/DomUtils";
import { usePageActive } from "presentation/hook/usePageActive";
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import NotificationVM from "presentation/viewModel/workspace/NotificationVM/NotificationVM";
import { useEffect, useMemo, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { UnderConstruction } from "../UnderConstruction/UnderConstruction";

export interface IMicroFrontend {
    path: string;
    entrypoint: string;
    isModelOpen?: boolean;
    sectionId?: string;
    containerId?: string;
    jsonData?: string;
}

export const MicroFrontend = (props: IMicroFrontend) => {
    const { path, entrypoint, isModelOpen, sectionId = '', containerId, jsonData } = props;
    const [workspaceState, setWorkspaceState] = useWorkspaceTracked();
    const [loader, setLoader] = useState(true);
    const [isShowUnderConstruction, setIsShowUnderConstruction] = useState(false);
    const notificationVM = useMemo(() => NotificationVM({ dispatch: [setWorkspaceState], }), [setWorkspaceState]);

    const memoDefaultPageInformation = useMemo(() => {
        return isModelOpen ? '' : workspaceState.defaultPageInformation;
    }, [workspaceState.defaultPageInformation, isModelOpen]);

    useEffect(() => {
        let className: string = DomUtils.getMfeClassName(entrypoint, sectionId);
        setLoader(true);

        fetch(`${path}/asset-manifest.json`, { cache: 'no-cache' })
            .then((res) => res.json())
            .then((manifest) => {
                DomUtils.bindEntryPoints(manifest.otherEntrypoints, className, path, entrypoint, sectionId, jsonData);
                setLoader(false);
                setIsShowUnderConstruction(false);
            })
            .catch((e) => {
                setLoader(false);
                setIsShowUnderConstruction(true);
            });

        return () => DomUtils.unMountHtml(className);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entrypoint, path, memoDefaultPageInformation, jsonData, notificationVM]);
    
    usePageActive(entrypoint, workspaceState.identifier);

    if (isShowUnderConstruction) return <UnderConstruction text={commonNotification.mfeFailure.msg} />;

    if (loader) return <Loader Indicator="Stripe" size="Large" />;
    
    return <div className="mfe-div-container" id={containerId ? containerId : DomUtils.mfeContainer(entrypoint, sectionId)} style={{ width: '100%', height: '100%', overflow: 'auto' }} />;
};
