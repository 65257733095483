import {Dispatch, SetStateAction} from "react";
import BaseViewModel from "../../BaseViewModel";
import {WorkspaceModel} from "../../../model/workspaceModel";
import { E_Notification_Type } from "presentation/view/components/workspace/Notification/Notification";

function getNotificationData(isActiveScreen: boolean, isShowNotification: boolean, notificationType: E_Notification_Type, notificationMessage: string) {
    return {
        isActiveScreen: isActiveScreen,
        isShowNotification: isShowNotification,
        notificationType: notificationType,
        notificationMessage: notificationMessage
    }
}

interface NotificationVMProps extends BaseViewModel {
    dispatch: [ Dispatch<SetStateAction<WorkspaceModel>> | ((value: SetStateAction<WorkspaceModel>) => void), ]
}

const NotificationVM = ({ dispatch }: NotificationVMProps) => {
    const [workspaceDispatch] = dispatch;

    //TODO: should not use any... but change later if have time.
    const clearNotification = () => {
        workspaceDispatch((prevState: WorkspaceModel) => ({ ...prevState, notification: { ...getNotificationData(false, false, E_Notification_Type.ALERT, '') } }));
    };

    const showNotification = (notificationType: E_Notification_Type, notificationMessage: string) => {
        workspaceDispatch((prevState: WorkspaceModel) => ({ ...prevState, notification: { ...getNotificationData(false, true, notificationType, notificationMessage) } }));
    }

    return {
        clearNotification,
        showNotification,
    };
};

export default NotificationVM;
