import "presentation/style/index_index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Login } from "presentation/view/components/workspace/Login/Login";
// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import React from "react";

// declare global {
//     interface Window {
//         [key: string]: any
//     }
// }

// let currentScript = document.currentScript
// if (currentScript != null) {
//     let appShellId: any = document.currentScript?.getAttribute("app-name")
//     if (appShellId == null) {
//         appShellId = 'root'
//     }
//     const appShellIdNode = document.getElementById(appShellId)
//     const root = appShellIdNode ? createRoot(appShellIdNode) : undefined; // createRoot(container!) if you use TypeScript
//     root?.render(
//         <React.Fragment>
//             <Login config={process.env.PUBLIC_URL + '/keycloak.json'}>
//                 <App />
//             </Login>
//         </React.Fragment>
//     );
//     // root?.render(
//     //     <App></App>
//     // );
// }

ReactDOM.render(
    <React.Fragment>
        <Login config={process.env.PUBLIC_URL + '/keycloak.json'}>
            <App/>
        </Login>
    </React.Fragment>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();