import BaseViewModel from "../../BaseViewModel";
import {EnvironmentVariableRepo} from "domain/repository/EnvironmentVariable/EnvironmentVariableRepo";
import {Dispatch, SetStateAction} from "react";
import {WorkspaceModel} from "presentation/model/workspaceModel";

interface EnvironmentVariableVMProps extends BaseViewModel {
    dispatch: [
            Dispatch<SetStateAction<WorkspaceModel>> | ((value: SetStateAction<WorkspaceModel>) => void),
    ]
    environmentParameterRepo: EnvironmentVariableRepo;
}

const EnvironmentVariableVM = ({dispatch, environmentParameterRepo}: EnvironmentVariableVMProps) => {
    const [workspaceDispatch] = dispatch

    const setupEnvironmentVariable = () => {
        environmentParameterRepo.getEnvironmentVariable().then((res) => {
            workspaceDispatch(prevState => {
                return {
                    ...prevState,
                    environmentVariable: res
                }
            });
        })
    };

    return {
        setupEnvironmentVariable: setupEnvironmentVariable,
    };
};

export default EnvironmentVariableVM;
