import BaseViewModel from "../../BaseViewModel";
import {
    UserActivityMFEAccessRepository
} from "domain/repository/UserActivityMFEAccess/UserActivityMFEAccessRepo";
import {UserActivityMFEAccessEntity} from "domain/entity/UserTracking/UserActivityMFEAccessEntity";

interface PageActiveVMProps extends BaseViewModel {
    dispatch: [];
    userActivityMFEAccessRepo: UserActivityMFEAccessRepository;
}

const PageActiveVM = ({dispatch, userActivityMFEAccessRepo}: PageActiveVMProps) => {
    const uploadUserActivityMFEAccess = (userActivityMFEAccess: UserActivityMFEAccessEntity) => {
        userActivityMFEAccessRepo.createNewMFEAccess(userActivityMFEAccess)
    };

    return {
        uploadUserActivityMFEAccess: uploadUserActivityMFEAccess,
    };
};

export default PageActiveVM;
