export interface ArpitaTemplateEntity {
  id: string;
  name: string;
  pinned: boolean;
  isMaster: boolean;
  isOpen: boolean;
  dataSet?: string;
  templateType?: string;
}

export const EMPTY_ARPITA_TEMPLATE_ENTITY: ArpitaTemplateEntity = {
  id: '',
  name: '',
  pinned: false,
  isMaster: false,
  isOpen: false,
  dataSet: '',
  templateType: '',
}

export interface IArpitaModalOption {
  showFullScreen: boolean;
  isResisable: boolean;
  isFullScreen: boolean;
  isMoveable: boolean;
  leftPosition?: string;
  topPosition?: string;
  showModalAgian?: boolean;
}

export interface ArpitaOpenTemplateEntity {
  openTId: string;
  templateId: string;
  template?: ArpitaTemplateEntity | undefined;
  position: number;
  modalOptions?: IArpitaModalOption;
  elementId: string;
  jsonData?: string;
}

export interface IArpitaOpenTemplateList {
  [templateId: string]: ArpitaOpenTemplateEntity;
}

export enum E_ArpitaHandlerAction {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  NEW = 'NEW',
  PIN = 'PIN',
  FOCUS = 'FOCUS',
  UNPIN = 'UNPIN',
  CLEAR = 'CLEAR',
  CREATE_NEW_TEMPLATE = 'CREATE_NEW_TEMPLATE',
  TEMPLATE_NAME_CHNAGE = 'TEMPLATE_NAME_CHNAGE',
  CREATE_DATATABLE = 'CREATE_DATATABLE',
  CREATE_TEMPLATE_FROM_SELECTION = 'CREATE_TEMPLATE_FROM_SELECTION',
  OPEN_FROM_MORE = 'OPEN_FROM_MORE',
  FULLSCREEN = 'FULLSCREEN',
  RESTORE_FROM_MINIMISE = 'RESTORE_FROM_MINIMISE',
};

export function makeArpitaTemplate(data: any) {
  return {
    ...EMPTY_ARPITA_TEMPLATE_ENTITY,
    id: data.id,
    name: data.templateName,
    pinned: data.pinned,
    isMaster: data.isMaster,
  }
}

export function makeArpitaModalOptions(isResisable: boolean | IArpitaModalOption = true, isFullScreen: boolean = true, isMoveable: boolean = true) {
  if (typeof isResisable === 'boolean') {
    return {
      showFullScreen: true,
      isResisable,
      isFullScreen,
      isMoveable,
    }
  } else if (typeof isResisable === 'object') {
    return {
      showFullScreen: true,
      isResisable: isResisable.isResisable,
      isFullScreen: isFullScreen,
      isMoveable: isResisable.isMoveable,
    }
  }
}