import { ANAInfo } from "presentation/constant/ANAInfo/ANAInfo";
import { useEffect } from "react";
import { useANAInfo } from "./useANAInfo";

const REQUEST_ANA_INFO_EVENT = 'request-ana-info'
const RESPONSE_ANA_INFO_EVENT = 'response-ana-info'

export const useANAInfoRequestReceiver = () => {
    const { userName, email, token, buToken, currentBu, availableBuList, allNbisivPermission, operatingUnit, currentOperatingCompany, 
        availabeOperatingCompanies, entryPoint, country, timezone} = useANAInfo();

    useEffect(() => {
        const response: ANAInfo = {
            allNbisivPermission: allNbisivPermission,
            currentBu: currentBu,
            availableBuList: availableBuList,
            token: token,
            email: email,
            buToken: buToken,
            operatingUnit: operatingUnit,
            currentOperatingCompany: currentOperatingCompany,
            availableOperatingCompanies: availabeOperatingCompanies,
            entryPoint: entryPoint,
            userName: userName,
            country: country,
            timezone: timezone,
        }
        const sendANAInfo = () => {
            document.dispatchEvent(new CustomEvent(RESPONSE_ANA_INFO_EVENT, {detail: response}));
        }

        sendANAInfo(); 
        document.addEventListener(REQUEST_ANA_INFO_EVENT, sendANAInfo)
        return () => {
            document.removeEventListener(REQUEST_ANA_INFO_EVENT, sendANAInfo)
        }
    }, [allNbisivPermission, userName, currentBu, availableBuList, token, buToken, email, operatingUnit, currentOperatingCompany, 
        availabeOperatingCompanies, entryPoint, country, timezone])
};
