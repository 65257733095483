import { FrontendConsoleTrackingEntity } from "domain/entity/UserTracking/FrontendConsoleTrackingEntity";
import { FrontendConsoleTrackingRepo } from "domain/repository/FrontendConsoleTracking/FrontendConsoleTrackingRepo";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";

export const FrontendConsoleTrackingRepoImpl = (): FrontendConsoleTrackingRepo => {    
    const url = '/v1/frontendconsoletracking';

    const createNewFrontendConsoleTracking = async(newData: FrontendConsoleTrackingEntity) => {
        await axiosPostData(authAxiosInstance, url, newData);
    };

    return {
        createNewFrontendConsoleTracking: createNewFrontendConsoleTracking,
    };
};
