import { useEffect } from 'react';

function usePreventZoom({ scrollCheck = true, keyboardCheck = true } = {}) {
  useEffect(() => {
    const handleKeydown = (e:any) => {
      if (keyboardCheck && e.ctrlKey && [61, 107, 173, 109, 187, 189].includes(e.keyCode)) {
        e.preventDefault();
      }
    };

    const handleWheel = (e:any) => {
      if (scrollCheck && e.ctrlKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("wheel", handleWheel);
    };
  }, [scrollCheck, keyboardCheck]);
}

export default usePreventZoom;