import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { useMemo, useState } from "react";
import { CommonField, FieldType, HPHToggle, IFieldValue } from "veronica-ui-component/dist/component/core"

export enum E_Template_Type {
  TEMPLATE_LIST = 0,
  MASTER = 1,
  TEMPLATE = 2,
  DATATABLE = 3,
}

interface IArpitaModalHeader {
  templateType: E_Template_Type,
  templateName: string;
}

export const ArpitaModalHeader: React.FC<IArpitaModalHeader> = (props: IArpitaModalHeader) => {
  const { templateType = E_Template_Type.TEMPLATE_LIST, templateName } = props;
  const [isAutoRefresh, setIsAutoRefresh] = useState<boolean>(true);
  const [cellUpdateOnly, setCellUpdateOnly] = useState<boolean>(false);

  const getListHeader = useMemo(() => <>
    <div className="md-header-box no">
      <span className="md-header-title" style={{ flexGrow: '1' }}><span className="template-list">TEMPLATE LIST</span></span>
    </div>
  </>, []);

  const getTemplateHeader = useMemo(() => <>
    <div className="md-header-box">
      {templateType === E_Template_Type.MASTER && <span className="md-header-title" style={{ flexGrow: '1' }}><div className={`lozenges orange`}><span>Master</span></div><span>{templateName}</span></span>}
      {templateType === E_Template_Type.TEMPLATE && <span className="md-header-title" style={{ flexGrow: '1' }}><div className={`lozenges blue`}><span>Template</span></div><span>{templateName}</span></span>}
      {templateType === E_Template_Type.DATATABLE && <span className="md-header-title" style={{ flexGrow: '1' }}><div className={`lozenges dblue`}><span>Data Table</span></div><span>{templateName}</span></span>}
      <div className="md-header-option" style={{ flexGrow: 0 }}>
        <div className="auto-refresh">
          <HPHToggle label={'Auto Refresh'} value={isAutoRefresh} check={isAutoRefresh} onChange={(e) => { setIsAutoRefresh((prevState) => !prevState); customDispatchEvent(E_Type_Of_Event.ARPITA_EVENT, E_Custom_Dispatch_Event.AUTO_REFRESH, { isRefresh: !isAutoRefresh }); }} />
        </div>
        <CommonField
          errorMessages={{}}
          isShowOptional={false}
          isReadOnly={false}
          fieldLabel={''}
          fieldValue={cellUpdateOnly ? ['1'] : null}
          isSaveClicked={false}
          fieldType={FieldType.CHECKBOX_GROUP}
          fieldKey={'cellUpdateOnly'}
          requiredFieldList={[]}
          options={[{ name: 'Cell Update Only', key: '1', disabled: false }]}
          onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
            if (typeof fieldValue === 'string') { setCellUpdateOnly(true); customDispatchEvent(E_Type_Of_Event.ARPITA_EVENT, E_Custom_Dispatch_Event.CELL_UPDATE_ONLY, { isPartial: !cellUpdateOnly }) }
            if (typeof fieldValue === 'object') { setCellUpdateOnly(false); customDispatchEvent(E_Type_Of_Event.ARPITA_EVENT, E_Custom_Dispatch_Event.CELL_UPDATE_ONLY, { isPartial: !cellUpdateOnly }) }
          }} />
      </div>
    </div>
  </>, [templateType, templateName, cellUpdateOnly, isAutoRefresh]);

  if (templateType === E_Template_Type.TEMPLATE_LIST) return getListHeader;

  return getTemplateHeader;
}